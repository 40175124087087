import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Select from 'react-select';
import axios from '../../../../../services/AxiosInstance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';
import { getApiUrl } from '../../../../../utils/ApiUtils';

const typeOptions = [
  { value: 'company', label: 'Företag' },
  { value: 'individual', label: 'Privatperson' },
];

const addressTypeOptions = [
  { value: 'billing', label: 'Fakturaadress' },
  { value: 'shipping', label: 'Leveransadress' },
  { value: 'other', label: 'Annan adress' },
];

const NewCustomerModal = ({ show, handleClose, handleSave, handleUpdate, customerDetails }) => {
  const isEditMode = !!customerDetails;

  const defaultCustomerState = {
    id: '',
    type: 'company',
    orgnumber: '',
    vatnumber: '',
    name: '',
    first_name: '',
    last_name: '',
    phone: '',
    payment_terms: '',
    addresses: [
      {
        address_type: 'billing',
        street1: '',
        street2: '',
        city: '',
        postalcode: '',
        country: '',
      },
    ],
    contacts: [
      {
        contact_name: '',
        contact_email: '',
        contact_phone: '',
        role: '',
      },
    ],
  };

  const [customerData, setCustomerData] = useState(defaultCustomerState);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    if (show) {
      if (isEditMode && customerDetails) {
        const {
          id,
          type,
          orgnumber,
          vatnumber,
          name,
          first_name,
          last_name,
          phone,
          payment_terms,
          addresses = [],
          contacts = [],
        } = customerDetails;

        setCustomerData({
          id: id || '',
          type: type || 'company',
          orgnumber: orgnumber || '',
          vatnumber: vatnumber || '',
          name: name || '',
          first_name: first_name || '',
          last_name: last_name || '',
          phone: phone || '',
          payment_terms: payment_terms || '',
          addresses: addresses.length > 0 ? addresses : [
            {
              address_type: 'billing',
              street1: '',
              street2: '',
              city: '',
              postalcode: '',
              country: '',
            },
          ],
          contacts: contacts.length > 0 ? contacts : [
            {
              contact_name: '',
              contact_email: '',
              contact_phone: '',
              role: '',
            },
          ],
        });
      } else {
        setCustomerData(defaultCustomerState);
      }
      setCompanies([]);
      setSelectedCompany(null);
    }
  }, [show, isEditMode, customerDetails]);

  const handleInputChange = (field) => (e) => {
    setCustomerData({ ...customerData, [field]: e.target.value ?? '' });
  };

  const handleTypeChange = (selectedOption) => {
    setCustomerData({ ...customerData, type: selectedOption.value });
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...customerData.contacts];
    updatedContacts[index][field] = value ?? '';
    setCustomerData({ ...customerData, contacts: updatedContacts });
  };

  const addNewContact = () => {
    setCustomerData({
      ...customerData,
      contacts: [
        ...customerData.contacts,
        { contact_name: '', contact_email: '', contact_phone: '', role: '' },
      ],
    });
  };

  const removeContact = (index) => {
    const updatedContacts = [...customerData.contacts];
    updatedContacts.splice(index, 1);
    setCustomerData({ ...customerData, contacts: updatedContacts });
  };

  const handleAddressChange = (index, field, value) => {
    const updatedAddresses = [...customerData.addresses];
    updatedAddresses[index][field] = value ?? '';
    setCustomerData({ ...customerData, addresses: updatedAddresses });
  };

  const handleAddressTypeChange = (index, selectedOption) => {
    const updatedAddresses = [...customerData.addresses];
    updatedAddresses[index].address_type = selectedOption.value;
    setCustomerData({ ...customerData, addresses: updatedAddresses });
  };

  const addNewAddress = () => {
    setCustomerData({
      ...customerData,
      addresses: [
        ...customerData.addresses,
        { address_type: 'billing', street1: '', street2: '', city: '', postalcode: '', country: '' },
      ],
    });
  };

  const removeAddress = (index) => {
    const updatedAddresses = [...customerData.addresses];
    updatedAddresses.splice(index, 1);
    setCustomerData({ ...customerData, addresses: updatedAddresses });
  };

  const searchCompany = async (inputValue) => {
    if (!inputValue) return;

    try {
      const searchCompanyApiUrl = getApiUrl('generic/search-company');
      const response = await axios.get(searchCompanyApiUrl, {
        params: { query: inputValue },
      });

      setCompanies(
        response.data.hits.map((company) => ({
          label: `${company.document.names[0].nameOrIdentifier} (${company.document.registrationNumber})`,
          value: company,
        }))
      );
    } catch (err) {
      console.error('Error fetching company data:', err.message);
      swal(
        'Fel',
        'Kunde inte hämta företagsinformation, försök igen senare eller ange informationen manuellt.',
        'error'
      );
    }
  };

  const handleCompanySelect = (selectedOption) => {
    const company = selectedOption.value;
    setSelectedCompany(company);

    const visitingAddress = company.document.addresses?.find(addr => addr.addressType === 'visitingAddress');
    const mailAddress = company.document.addresses?.find(addr => addr.addressType === 'mailAddress');
    const selectedAddress = visitingAddress || mailAddress || {};

    const phoneNumber = company.document.phoneNumbers?.[0]?.e164PhoneNumber || '';
    const email = company.document.emailAddresses?.[0] || '';
    const compName = company.document.names?.[0]?.nameOrIdentifier || '';

    const updatedAddresses = [
      {
        address_type: 'billing',
        street1: ((selectedAddress.street || '') + ' ' + (selectedAddress.houseNumber || '')).trim(),
        street2: '',
        city: selectedAddress.city || '',
        postalcode: selectedAddress.postalCode || '',
        country: company.document.iso3166CountryCode || '',
      },
    ];

    const updatedContacts = [
      {
        contact_name: '',
        contact_email: email,
        contact_phone: phoneNumber,
        role: '',
      },
    ];

    setCustomerData({
      ...customerData,
      type: 'company',
      orgnumber: company.document.registrationNumber || '',
      vatnumber: company.document.isRegisteredForVAT ? `SE${company.document.registrationNumber}01` : '',
      name: compName,
      first_name: '',
      last_name: '',
      phone: phoneNumber,
      payment_terms: '',
      addresses: updatedAddresses,
      contacts: updatedContacts,
    });
  };

  const handleSaveClick = () => {
    const payload = {
      type: customerData.type,
      orgnumber: customerData.type === 'company' ? customerData.orgnumber || '' : '',
      vatnumber: customerData.type === 'company' ? customerData.vatnumber || '' : '',
      name: customerData.type === 'company' ? customerData.name || '' : '',
      first_name: customerData.type === 'individual' ? customerData.first_name || '' : '',
      last_name: customerData.type === 'individual' ? customerData.last_name || '' : '',
      phone: customerData.phone || '',
      payment_terms: customerData.payment_terms || '',
      addresses: customerData.addresses.map((addr) => ({
        address_type: addr.address_type,
        street1: addr.street1 || '',
        street2: addr.street2 || '',
        city: addr.city || '',
        postalcode: addr.postalcode || '',
        country: addr.country || '',
      })),
      contacts: customerData.contacts.map((ct) => ({
        contact_name: ct.contact_name || '',
        contact_email: ct.contact_email || '',
        contact_phone: ct.contact_phone || '',
        role: ct.role || '',
      })),
    };

    if (isEditMode) {
      payload.id = customerData.id;
      handleUpdate(payload);
    } else {
      handleSave(payload);
    }
    handleClose();
  };

  return (
    <Modal
      className='fade bd-example-modal-lg'
      size='lg'
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>{isEditMode ? 'Redigera kund' : 'Ny kund'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="basic-form">
          <div className="col-xl-12">
            {!isEditMode && (
              <>
                <label>Typ av kund</label>
                <Select
                  options={typeOptions}
                  value={typeOptions.find((opt) => opt.value === customerData.type)}
                  onChange={handleTypeChange}
                  className="mb-3"
                />
              </>
            )}

            {!isEditMode && customerData.type === 'company' && (
              <>
                <label>Sök företag</label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Ange företagsnamn eller org-nummer"
                    value={customerData.name || ''}
                    onChange={handleInputChange('name')}
                  />
                  <Button variant="primary" onClick={() => searchCompany(customerData.name)}>
                    Hämta företagsinformation
                  </Button>
                </div>
                {companies.length > 0 && (
                  <>
                    <label>Hittade {companies.length} företag</label>
                    <Select
                      options={companies}
                      onChange={handleCompanySelect}
                      placeholder="Välj ett företag"
                      className='mb-5'
                      value={
                        selectedCompany
                          ? {
                            label: selectedCompany.document.names[0].nameOrIdentifier,
                            value: selectedCompany
                          }
                          : null
                      }
                    />
                  </>
                )}
              </>
            )}

            <form onSubmit={(e) => e.preventDefault()}>
              {customerData.type === 'company' && (
                <>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>Företagsnamn</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerData.name || ''}
                        onChange={handleInputChange('name')}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Org-nummer</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerData.orgnumber || ''}
                        onChange={handleInputChange('orgnumber')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>Vat-nummer</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerData.vatnumber || ''}
                        onChange={handleInputChange('vatnumber')}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Telefonnummer</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerData.phone || ''}
                        onChange={handleInputChange('phone')}
                      />
                    </div>
                  </div>
                </>
              )}

              {customerData.type === 'individual' && (
                <>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>Förnamn</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerData.first_name || ''}
                        onChange={handleInputChange('first_name')}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>Efternamn</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerData.last_name || ''}
                        onChange={handleInputChange('last_name')}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>Telefonnummer</label>
                      <input
                        type="text"
                        className="form-control"
                        value={customerData.phone || ''}
                        onChange={handleInputChange('phone')}
                      />
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="form-group mb-3 col-md-6">
                  <label>Betalningsvillkor (dagar)</label>
                  <input
                    type="number"
                    className="form-control"
                    value={customerData.payment_terms || ''}
                    onChange={handleInputChange('payment_terms')}
                  />
                </div>
              </div>
            </form>

            <hr />
            <h5>Adresser</h5>
            {customerData.addresses.map((addr, idx) => (
              <div key={idx} className="mb-4 border p-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h6>Adress {idx + 1}</h6>
                  {customerData.addresses.length > 1 && (
                    <Button variant="link" onClick={() => removeAddress(idx)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  )}
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-4">
                    <label>Typ</label>
                    <Select
                      options={addressTypeOptions}
                      value={addressTypeOptions.find(o => o.value === addr.address_type)}
                      onChange={(opt) => handleAddressTypeChange(idx, opt)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-8">
                    <label>Gata</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addr.street1 || ''}
                      onChange={(e) => handleAddressChange(idx, 'street1', e.target.value)}
                    />
                  </div>
                </div>
                {/*<div className="row">
                  <div className="form-group mb-3 col-md-12">
                    <label>Gata 2</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addr.street2 || ''}
                      onChange={(e) => handleAddressChange(idx, 'street2', e.target.value)}
                    />
                  </div>
                </div>*/}
                <div className="row">
                  <div className="form-group mb-3 col-md-4">
                    <label>Ort</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addr.city || ''}
                      onChange={(e) => handleAddressChange(idx, 'city', e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-4">
                    <label>Postnummer</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addr.postalcode || ''}
                      onChange={(e) => handleAddressChange(idx, 'postalcode', e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-4">
                    <label>Land</label>
                    <input
                      type="text"
                      className="form-control"
                      value={addr.country || ''}
                      onChange={(e) => handleAddressChange(idx, 'country', e.target.value)}
                    />
                  </div>
                </div>
              </div>
            ))}
            <Button variant="link" onClick={addNewAddress}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />Lägg till ny adress
            </Button>

            <hr />
            <h5>Kontaktpersoner</h5>
            {customerData.contacts.map((contact, index) => (
              <div key={index} className="mb-4 border p-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h6>Kontakt {index + 1}</h6>
                  {customerData.contacts.length > 1 && (
                    <Button variant="link" onClick={() => removeContact(index)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  )}
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-4">
                    <label>Namn</label>
                    <input
                      type="text"
                      className="form-control"
                      value={contact.contact_name || ''}
                      onChange={(e) => handleContactChange(index, 'contact_name', e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-4">
                    <label>E-post</label>
                    <input
                      type="email"
                      className="form-control"
                      value={contact.contact_email || ''}
                      onChange={(e) => handleContactChange(index, 'contact_email', e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-3">
                    <label>Telefon</label>
                    <input
                      type="text"
                      className="form-control"
                      value={contact.contact_phone || ''}
                      onChange={(e) => handleContactChange(index, 'contact_phone', e.target.value)}
                    />
                  </div>
                  {/*<div className="form-group mb-3 col-md-1">
                    <label>Roll</label>
                    <input
                      type="text"
                      className="form-control"
                      value={contact.role || ''}
                      onChange={(e) => handleContactChange(index, 'role', e.target.value)}
                    />
                  </div>*/}
                </div>
              </div>
            ))}
            <Button variant="link" onClick={addNewContact}>
              <FontAwesomeIcon icon={faPlus} className="me-2" />Lägg till ny kontaktperson
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Avbryt
        </Button>
        <Button variant="primary" onClick={handleSaveClick}>
          {isEditMode ? 'Uppdatera' : 'Spara'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewCustomerModal;
