// NonEditableEmployeePersonalDetails.js

import React from 'react';

const NonEditableEmployeePersonalDetails = ({ employee }) => {
  return (
    <div className="basic-form">
      <div className="row mb-3">
        {/* Förnamn and Efternamn */}
        <div className="col-md-6">
          <strong>Förnamn:</strong> {employee.firstname}
        </div>
        <div className="col-md-6">
          <strong>Efternamn:</strong> {employee.lastname}
        </div>
      </div>
      <div className="row mb-3">
        {/* Personnummer and Jobbtitel */}
        <div className="col-md-6">
          <strong>Personnummer:</strong> {employee.ssn}
        </div>
        <div className="col-md-6">
          <strong>Jobbtitel:</strong> {employee.jobtitle}
        </div>
      </div>
      <div className="row mb-3">
        {/* Clearingnummer and Bankkonto */}
        <div className="col-md-6">
          <strong>Clearingnummer:</strong> {employee.clearingnbr}
        </div>
        <div className="col-md-6">
          <strong>Bankkonto:</strong> {employee.accountnbr}
        </div>
      </div>
      <div className="row mb-3">
        {/* Telefonnummer and Email */}
        <div className="col-md-6">
          <strong>Telefonnummer:</strong> {employee.phonenumber}
        </div>
        <div className="col-md-6">
          <strong>Email:</strong> {employee.email}
        </div>
      </div>
      <div className="row mb-3">
        {/* Address, Postnummer, and City */}
        <div className="col-md-6">
          <strong>Adress:</strong> {employee.address}
        </div>
        <div className="col-md-3">
          <strong>Postnummer:</strong> {employee.postnumber}
        </div>
        <div className="col-md-3">
          <strong>Ort:</strong> {employee.city}
        </div>
      </div>
      <div className="row mb-3">
        {/* Status */}
        <div className="col-md-6">
          <strong>Status:</strong> {employee.status === 'active' ? 'Aktiv' : 'Inaktiv'}
        </div>
      </div>
    </div>
  );
};

export default NonEditableEmployeePersonalDetails;
