import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../../../services/AxiosInstance';
import InvoicesTable from '../../Invoices/InvoicesTable';

import { getApiUrl } from '../../../../../utils/ApiUtils';

const ViewCustomerDetailed = () => {
  const { companyId, customerId } = useParams();
  const [customer, setCustomer] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [statistics, setStatistics] = useState({ totalEarned: 0 });

  useEffect(() => {
    // Fetch customer details
    const fetchCustomerDetails = async () => {
      try {
        const getCustomerDetailsUrl = getApiUrl(`${companyId}/sales/customers/${customerId}`);
        const response = await axios.get(getCustomerDetailsUrl, { withCredentials: true });
        setCustomer(response.data);
      } catch (error) {
        console.error('Error fetching customer details:', error);
      }
    };

    // Fetch customer invoices
    const fetchCustomerInvoices = async () => {
      try {
        const getCustomerInvoicesApiUrl = getApiUrl(`${companyId}/sales/customers/${customerId}/invoices`);
        const response = await axios.get(getCustomerInvoicesApiUrl, { withCredentials: true });
        setInvoices(response.data);
        calculateStatistics(response.data);
      } catch (error) {
        console.error('Error fetching customer invoices:', error);
      }
    };

    fetchCustomerDetails();
    fetchCustomerInvoices();
  }, [companyId, customerId]);

  const calculateStatistics = (invoices) => {
    // Assuming invoices have an 'amount' field
    const totalEarned = invoices.reduce((sum, invoice) => sum + invoice.amount, 0);
    setStatistics({ totalEarned });
  };

  if (!customer) {
    return <div>Loading...</div>;
  }

  const isCompany = customer.type === 'company';

  return (
    <>
      <div className="col-12">

        <div className="card mb-4">
          <div className="card-header">
            <h4 className="card-title">Om kunden</h4>
          </div>
          <div className="card-body pb-3 d-flex flex-wrap justify-content-between align-items-top">

            {/* Display fields depending on customer type */}
            {isCompany ? (
              <>
                <div className="me-3 mb-3">
                  <p className="mb-2">Namn</p>
                  <h5 className="mb-0">{customer.name}</h5>
                </div>
                <div className="me-3 mb-3">
                  <p className="mb-2">Org. nummer</p>
                  <h5 className="mb-0">{customer.orgnumber}</h5>
                </div>
                <div className="me-3 mb-3">
                  <p className="mb-2">VAT nummer</p>
                  <h5 className="mb-0">{customer.vatnumber}</h5>
                </div>
              </>
            ) : (
              <>
                <div className="me-3 mb-3">
                  <p className="mb-2">Förnamn</p>
                  <h5 className="mb-0">{customer.first_name}</h5>
                </div>
                <div className="me-3 mb-3">
                  <p className="mb-2">Efternamn</p>
                  <h5 className="mb-0">{customer.last_name}</h5>
                </div>
              </>
            )}

            <div className="me-3 mb-3">
              <p className="mb-2">Telefon</p>
              <h5 className="mb-0">{customer.phone}</h5>
            </div>

            <div className="me-3 mb-3">
              <p className="mb-2">Betalningsvillkor (dagar)</p>
              <h5 className="mb-0">{customer.payment_terms || '-'}</h5>
            </div>
          </div>
        </div>

        {/* Addresses Section */}
        {customer.addresses && customer.addresses.length > 0 && (
          <div className="card mb-4">
            <div className="card-header">
              <h4 className="card-title">Adresser</h4>
            </div>
            <div className="card-body">
              {customer.addresses.map((addr, idx) => (
                <div key={idx} className="mb-3 border p-3">
                  <h5>{addr.address_type === 'billing' ? 'Fakturaadress' :
                    addr.address_type === 'shipping' ? 'Leveransadress' : 'Annan adress'}</h5>
                  <p className="mb-1">{addr.street1}{addr.street2 ? `, ${addr.street2}` : ''}</p>
                  <p className="mb-1">{addr.postalcode} {addr.city}</p>
                  <p className="mb-1">{addr.country}</p>
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Contacts Section */}
        {customer.contacts && customer.contacts.length > 0 && (
          <div className="card mb-4">
            <div className="card-header">
              <h4 className="card-title">Kontaktpersoner</h4>
            </div>
            <div className="card-body">
              {customer.contacts.map((contact, idx) => (
                <div key={idx} className="mb-3 border p-3">
                  <h5>{contact.contact_name || 'Okänd kontakt'}</h5>
                  {contact.role && <p className="mb-1"><strong>Roll: </strong>{contact.role}</p>}
                  {contact.contact_email && <p className="mb-1"><strong>E-post: </strong>{contact.contact_email}</p>}
                  {contact.contact_phone && <p className="mb-1"><strong>Telefon: </strong>{contact.contact_phone}</p>}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* Invoices Section */}
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Fakturor</h4>
          </div>
          <div className="card-body">
            <InvoicesTable invoices={invoices} />
          </div>
        </div>

        {/*
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Statistik</h4>
          </div>
          <div className="card-body">
            <p><strong>Total Earned:</strong> {statistics.totalEarned}</p>
          </div>
        </div>
        */}
      </div>
    </>
  );
};

export default ViewCustomerDetailed;
