import React, { useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel, DocumentLoadEvent } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Button, Row, Col } from 'react-bootstrap';

import { printPlugin } from '@react-pdf-viewer/print';
import '@react-pdf-viewer/print/lib/styles/index.css';

const PDFViewer = ({ fileUrl }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(null);

  // Initialize the print plugin inside the component
  const printPluginInstance = printPlugin();
  const { PrintButton } = printPluginInstance;

  const handleDocumentLoad = (doc) => {
    console.log(`Number of pages: ${doc.doc.numPages}`);
    setNumPages(doc.doc.numPages);
    setCurrentPage(1);
  };

  const onPageChange = (e) => {
    setCurrentPage(e.pageIndex + 1); // Since e.pageIndex is 0-based
  };

  return (
    <div className='rpv-core__viewer' style={{
      border: '1px solid rgba(0, 0, 0, 0.3)',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}>
      <div style={{ position: 'relative' }}>
        {/* Overlay container for the print button */}
        <div style={{ position: 'absolute', top: '30px', right: '30px', zIndex: 999 }}>
          <PrintButton />
        </div>

        <Row className="mb-3">
          <Col className="d-flex justify-content-center">
            {numPages > 1 && (
              <>
                <Button
                  disabled={currentPage === 1}
                  onClick={() => setCurrentPage((prev) => prev - 1)}
                  className="me-2"
                >
                  Föregående
                </Button>
                <span>Sida {currentPage} av {numPages}</span>
                <Button
                  disabled={currentPage === numPages}
                  onClick={() => setCurrentPage((prev) => prev + 1)}
                  className="ms-2"
                >
                  Nästa
                </Button>
              </>
            )}
          </Col>
        </Row>

        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
          <div style={{ height: '100%' }}>
            <Viewer
              fileUrl={fileUrl}
              defaultScale={SpecialZoomLevel.PageWidth}
              onDocumentLoad={handleDocumentLoad}
              currentPage={currentPage}
              onPageChange={(page) => onPageChange(page)}
              plugins={[printPluginInstance]}  // Add the print plugin here
            />
          </div>
        </Worker>
      </div>
    </div>
  );
};

export default PDFViewer;