// EmployeeDetailsComponent.js

import React from 'react';
import CreatableSelect from 'react-select/creatable';

const EmployeePersonalDetails = ({ formik }) => {
  const { values, handleChange, handleBlur, errors, touched, setFieldValue, setFieldTouched } = formik;

  // Predefined job titles
  const jobTitleOptions = [
    { value: 'Utvecklare', label: 'Utvecklare' },
    { value: 'Projektledare', label: 'Projektledare' },
    { value: 'Designer', label: 'Designer' },
    // Add more job titles as needed
  ];

  return (
    <>
      <div className="basic-form">
        <div className="row">
          {/* Förnamn */}
          <div className="form-group mb-3 col-md-4">
            <label>Förnamn</label>
            <input
              type="text"
              className={`form-control ${touched.firstname && errors.firstname ? 'is-invalid' : ''}`}
              name="firstname"
              value={values.firstname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.firstname && errors.firstname && (
              <div className="invalid-feedback">{errors.firstname}</div>
            )}
          </div>
          {/* Efternamn */}
          <div className="form-group mb-3 col-md-4">
            <label>Efternamn</label>
            <input
              type="text"
              className={`form-control ${touched.lastname && errors.lastname ? 'is-invalid' : ''}`}
              name="lastname"
              value={values.lastname}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.lastname && errors.lastname && (
              <div className="invalid-feedback">{errors.lastname}</div>
            )}
          </div>
        </div>
        {/* Personnummer and Jobbtitel */}
        <div className="row">
          {/* Personnummer */}
          <div className="form-group mb-3 col-md-4">
            <label>Personnummer</label>
            <input
              type="text"
              className={`form-control ${touched.ssn && errors.ssn ? 'is-invalid' : ''}`}
              name="ssn"
              value={values.ssn}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.ssn && errors.ssn && (
              <div className="invalid-feedback">{errors.ssn}</div>
            )}
          </div>
          {/* Jobbtitel */}
          <div className="form-group mb-3 col-md-4">
            <label>Jobbtitel</label>
            <CreatableSelect
              name="jobtitle"
              value={
                values.jobtitle
                  ? { value: values.jobtitle, label: values.jobtitle }
                  : null
              }
              onChange={(option) => setFieldValue('jobtitle', option ? option.value : '')}
              onBlur={() => setFieldTouched('jobtitle', true)}
              options={jobTitleOptions}
              placeholder="Välj eller skriv in en jobbtitel..."
            />
            {touched.jobtitle && errors.jobtitle && (
              <div className="text-danger mt-1">{errors.jobtitle}</div>
            )}
          </div>
        </div>
        {/* Clearingnummer and Bankkonto */}
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Clearingnummer</label>
            <input
              type="text"
              className={`form-control ${touched.clearingnumber && errors.clearingnumber ? 'is-invalid' : ''}`}
              name="clearingnumber"
              value={values.clearingnumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.clearingnumber && errors.clearingnumber && (
              <div className="invalid-feedback">{errors.clearingnumber}</div>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Bankkonto</label>
            <input
              type="text"
              className={`form-control ${touched.bankaccountnumber && errors.bankaccountnumber ? 'is-invalid' : ''}`}
              name="bankaccountnumber"
              value={values.bankaccountnumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.bankaccountnumber && errors.bankaccountnumber && (
              <div className="invalid-feedback">{errors.bankaccountnumber}</div>
            )}
          </div>
        </div>
        {/* Telefonnummer and Email */}
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Telefonnummer</label>
            <input
              type="text"
              className={`form-control ${touched.phonenumber && errors.phonenumber ? 'is-invalid' : ''}`}
              name="phonenumber"
              value={values.phonenumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.phonenumber && errors.phonenumber && (
              <div className="invalid-feedback">{errors.phonenumber}</div>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Email</label>
            <input
              type="email"
              className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.email && errors.email && (
              <div className="invalid-feedback">{errors.email}</div>
            )}
          </div>
        </div>
        {/* Adress, Postnummer, and Ort */}
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Adress</label>
            <input
              type="text"
              className={`form-control ${touched.address && errors.address ? 'is-invalid' : ''}`}
              name="address"
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.address && errors.address && (
              <div className="invalid-feedback">{errors.address}</div>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Postnummer</label>
            <input
              type="text"
              className={`form-control ${touched.postnummer && errors.postnummer ? 'is-invalid' : ''}`}
              name="postnummer"
              value={values.postnummer}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.postnummer && errors.postnummer && (
              <div className="invalid-feedback">{errors.postnummer}</div>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Ort</label>
            <input
              type="text"
              className={`form-control ${touched.ort && errors.ort ? 'is-invalid' : ''}`}
              name="ort"
              value={values.ort}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.ort && errors.ort && (
              <div className="invalid-feedback">{errors.ort}</div>
            )}
          </div>
        </div>
        {/* Aktivera anställd Checkbox */}
        <div className="form-group mt-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="active"
              checked={values.active}
              onChange={() => setFieldValue('active', !values.active)}
            />
            <label className="form-check-label">Aktivera anställd</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePersonalDetails;
