// ProductsAndArticlesCard.js

import React, { useEffect, useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import InvoiceItemsTable from './InvoiceItemsTable';
import { isEqual } from 'lodash';

export function calculateInvoiceTotals(items, disableRounding) {
  let subtotal = 0;
  let taxBreakdown = {};
  let grandTotal = 0;

  items.forEach((item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const unitprice = parseFloat(item.unitprice) || 0;
    const amount = quantity * unitprice;
    subtotal += amount;

    const taxRateValue = parseFloat(item.tax_rate) || 0;
    const taxAmount = (amount * taxRateValue) / 100;

    if (!taxBreakdown[taxRateValue]) {
      taxBreakdown[taxRateValue] = {
        taxRate: taxRateValue,
        totalAmount: 0,
        totalTax: 0,
      };
    }
    taxBreakdown[taxRateValue].totalAmount += amount;
    taxBreakdown[taxRateValue].totalTax += taxAmount;
  });

  const totalTaxes = Object.values(taxBreakdown).reduce(
    (acc, curr) => acc + curr.totalTax,
    0
  );
  grandTotal = subtotal + totalTaxes;

  let roundedTotal = grandTotal;
  let rounding = 0;

  if (!disableRounding) {
    roundedTotal = Math.round(grandTotal);
    rounding = roundedTotal - grandTotal;
  }

  return {
    subtotal: subtotal.toFixed(2),
    taxBreakdown,
    totalTaxes: totalTaxes.toFixed(2),
    grandTotal: grandTotal.toFixed(2),
    roundedTotal: roundedTotal.toFixed(2),
    rounding: rounding.toFixed(2),
  };
}

const ProductsAndArticlesCard = ({
  invoiceData,
  items,
  articles,
  handleAddInvoiceItem,
  handleDeleteInvoiceItem,
  handleUpdateInvoiceItem,
  handleCreateArticle,
  handleCheckChange,
  openCreateNewArticleModal,
}) => {
  const [totals, setTotals] = useState({
    subtotal: '0.00',
    taxBreakdown: {},
    totalTaxes: '0.00',
    grandTotal: '0.00',
    roundedTotal: '0.00',
    rounding: '0.00',
  });

  useEffect(() => {
    const calculatedTotals = calculateInvoiceTotals(
      items,
      invoiceData.disable_rounding
    );
    setTotals(calculatedTotals);
  }, [items, invoiceData.disable_rounding]);

  return (
    <Card>
      <Card.Body>
        <h4 className="fs-24 font-w400">Produkter/Artiklar</h4>
        <div className="col-xxl-12 mt-4 ">
          <InvoiceItemsTable
            items={items}
            articles={articles}
            handleAddItem={handleAddInvoiceItem}
            handleCreateArticle={handleCreateArticle}
            handleDeleteItem={handleDeleteInvoiceItem}
            updateRow={handleUpdateInvoiceItem}
            openCreateNewArticleModal={openCreateNewArticleModal}
          />
        </div>

        <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 mt-4 ms-4">
          <div className="mb-4">
            <div>
              <p className="fs-14 mb-2">Summa att betala</p>
              <span className="text-black me-2 font-w700">
                {invoiceData.disable_rounding
                  ? totals.grandTotal
                  : totals.roundedTotal}
              </span>
            </div>
          </div>
          <div className="mb-4">
            <div>
              <p className="fs-14 mb-2">Summa (exkl. moms)</p>
              <span className="text-black me-2 font-w700">
                {totals.subtotal}
              </span>
            </div>
          </div>
          {Object.keys(totals.taxBreakdown).map((taxRate, index) => (
            <div className="mb-4" key={index}>
              <div>
                <p className="fs-14 mb-2">Moms ({taxRate}%)</p>
                <span className="text-black me-2 font-w700">
                  {totals.taxBreakdown[taxRate].totalTax.toFixed(2)}
                </span>
              </div>
            </div>
          ))}
          <div className="mb-4">
            <div>
              <p className="fs-14 mb-2">Avrundat belopp</p>
              <span className="text-black me-2 font-w700">
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label={totals.rounding}
                  checked={!invoiceData.disable_rounding}
                  onChange={handleCheckChange}
                />
              </span>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProductsAndArticlesCard;
