import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import MyWallet from "./components/Dashboard/MyWallet";
//import InvoicesList from "./components/Dashboard/InvoicesList";
import CardCenter from "./components/Dashboard/CardCenter";
import TransactionDetails from "./components/Dashboard/TransactionDetails";

import Task from "./components/Dashboard/Task";

/// Dashboard - Verifications
import Verifications from "./components/Accounting/Verification/Verifications";

import FiscalYearDetails from "./components/Accounting/FiscalYearDetails";

import EmployeeList from "./components/AppsMenu/Personell/EmployeeList";
import NewEmployee from "./components/AppsMenu/Personell/NewEmployee";
import EditEmployee from "./components/AppsMenu/Personell/EditEmployee";
import ViewEmployee from "./components/AppsMenu/Personell/ViewEmployee";

import NewContract from "./components/AppsMenu/Personell/NewContract";

import PayrollPeriods from "./components/AppsMenu/Salary/PayrollPeriods";
import MonthlyPayrollPeriod from "./components/AppsMenu/Salary/MonthlyPayrollPeriod";
import Payslip from "./components/AppsMenu/Salary/Payslip";

//import CreateInvoice from "./components/AppsMenu/Invoices/CreateInvoice";
import CreateSupplierInvoice from "./components/AppsMenu/supplier-invoices/CreateSupplierInvoice";
import InvoiceList from "./components/AppsMenu/Invoices/InvoiceList";
//import EditInvoice from "./components/AppsMenu/Invoices/EditInvoice";
import ViewInvoice from "./components/AppsMenu/Invoices/ViewInvoice";
import CustomersTable from "./components/AppsMenu/Factoring/Customers/CustomersTable";
import ViewCustomerDetailed from "./components/AppsMenu/Factoring/Customers/ViewCustomerDetailed";
import ViewSupplierDetailed from "./components/AppsMenu/Factoring/Suppliers/ViewSupplierDetailed";
import SuppliersTable from "./components/AppsMenu/Factoring/Suppliers/SuppliersTable";
import ArticlesTable from "./components/AppsMenu/Factoring/Articles/ArticlesTable";
import InvoiceForm from "./components/AppsMenu/Invoices/InvoiceForm";

import SupplierInvoiceList from "./components/AppsMenu/supplier-invoices/SupplierInvoiceList";
import ViewSupplierInvoice from "./components/AppsMenu/supplier-invoices/ViewSupplierInvoice";

//import CreateVerification from "./components/Accounting/Verification/newbookwizard/NewVerWizard"
import CreateVerification from "./components/Accounting/Verification/NewVerification";

import CreateVerificationFromCopy from "./components/Accounting/Verification/CreateCopyVerification";
import EditVerification from "./components/Accounting/Verification/EditVerification";

import ViewSchedule from "./components/AppsMenu/Scheduling/ViewSchedule";

import CompanySettings from "./components/Settings/CompanySettings";


/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

import VatReportingPeriods from "./components/Reporting/Vat/VatReportingPeriods";
import VatPeriodDetails from "./components/Reporting/Vat/VatPeriodDetails";

import MainLedgerReport from "./components/Reporting/Ledger/MainLedgerReport";
import BalanceReport from "./components/Reporting/Balance/BalanceReport";
import ResultReport from "./components/Reporting/Result/ResultReport";


/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import { ThemeContext } from "../context/ThemeContext";

import UserSettings from "./components/Settings/UserSettings";

import AcceptInvite from "./pages/AcceptInvite";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: ":companyId/dashboard", component: Home },

    ///Apps - Fakturering
    { url: ":companyId/customers", component: CustomersTable },
    { url: ":companyId/customers/:customerId", component: ViewCustomerDetailed },
    { url: ":companyId/articles", component: ArticlesTable },
    { url: ":companyId/suppliers", component: SuppliersTable },
    { url: ":companyId/suppliers/:supplierId", component: ViewSupplierDetailed },

    { url: ":companyId/invoices", component: InvoiceList },
    { url: ":companyId/supplier-invoices", component: SupplierInvoiceList },
    { url: ":companyId/supplier-invoices/:invoiceId", component: ViewSupplierInvoice },
    { url: ":companyId/create-invoices", component: InvoiceForm },
    { url: ":companyId/create-supplier-invoices", component: CreateSupplierInvoice },
    { url: ":companyId/edit-invoice/:invoiceId", component: InvoiceForm },
    { url: ":companyId/view-invoice/:invoiceId", component: ViewInvoice },
    { url: ":companyId/card-center", component: CardCenter },
    { url: ":companyId/transaction-details", component: TransactionDetails },
    { url: ":companyId/task", component: Task },
    { url: ":companyId/verifications", component: Verifications },

    /// Apps
    //{ url: "app-profile", component: AppProfile },
    //{ url: "email-compose", component: Compose },
    //{ url: "email-inbox", component: Inbox },
    //{ url: "email-read", component: Read },
    //{ url: "app-calender", component: Calendar },
    //{ url: "post-details", component: PostDetails },

    //Employees
    { url: ":companyId/employees", component: EmployeeList },
    //{ url: "employee/:id", component: ViewEmployee },
    { url: ":companyId/create-employee", component: NewEmployee },
    { url: ":companyId/edit-employee/:id", component: EditEmployee },
    { url: ":companyId/schedule", component: ViewSchedule },

    //Contracts

    //{ url: "employee/:id/newcontract", component: NewContract },


    /// Form
    //{ url: "form-element", component: Element },
    //{ url: "form-wizard", component: Wizard },
    //{ url: "form-editor-summernote", component: SummerNote },
    //{ url: "form-pickers", component: Pickers },
    //{ url: "form-validation-jquery", component: jQueryValidation },
    //
    ///// table
    //{ url: 'table-filtering', component: FilteringTable },
    //{ url: 'table-sorting', component: SortingTable },
    //{ url: "table-datatable-basic", component: DataTable },
    //{ url: "table-bootstrap-basic", component: BootstrapTable },

    /// Settings
    { url: ":companyId/settings", component: CompanySettings },

    { url: ":companyId/user/:userId/settings", component: UserSettings },

    /// Reporting
    { url: ":companyId/vat-periods", component: VatReportingPeriods },
    { url: ":companyId/vat-period/:vatPeriodId", component: VatPeriodDetails },

    { url: ":companyId/reports/mainledger", component: MainLedgerReport },
    { url: ":companyId/reports/balance", component: BalanceReport },
    { url: ":companyId/reports/result", component: ResultReport },

    /// pages
    { url: "page-register", component: Registration },
    { url: "page-lock-screen", component: LockScreen },
    { url: "page-login", component: Login },
    { url: "page-forgot-password", component: ForgotPassword },
    { url: "page-error-400", component: Error400 },
    { url: "page-error-403", component: Error403 },
    { url: "page-error-404", component: Error404 },
    { url: "page-error-500", component: Error500 },
    { url: "page-error-503", component: Error503 },

  ];
  let path = window.location.pathname;
  path = path.split("/");
  const companyId = path[1];

  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");

  return (
    <>
      <div id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${menuToggle ? "menu-toggle" : ""
          }`}
      >
        {!pagePath && <Nav companyId={companyId} />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route key={i} exact path={`/${data.url}`} component={data.component} />
              ))}
              {/*<Route path="/:companyId/edit-invoice/:id" component={EditInvoice} />*/}
              <Route path="/:companyId/employee/:id/newcontract" component={NewContract} />
              <Route path="/:companyId/employee/:id" component={ViewEmployee} />
              {/*<Route path="/:companyId/supplier-invoice/:id" component={EditInvoice} />*/}
              <Route path="/:companyId/payroll/periods" component={PayrollPeriods} />
              <Route path="/:companyId/payroll/:year/:month" component={MonthlyPayrollPeriod} />
              <Route path="/:companyId/payslip/:id" component={Payslip} />
              <Route path="/:companyId/accounting/fiscal-years/:fiscalYearId" component={FiscalYearDetails} />

              {/*<Route path="/payroll/:year" component={MonthlyPayrollPeriod} />*/}
              <Route path='/:companyId/newverification' component={CreateVerification} />
              <Route path='/:companyId/edit-verification/:verificationId' component={EditVerification} />
              <Route path='/:companyId/copy-verification' component={CreateVerificationFromCopy} />

            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
