import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';

const NewArticleModal = ({ show, handleClose, handleSave, handleUpdate, articleDetails }) => {
  const isNewArticle = articleDetails === null;

  const [articlesData, setArticlesData] = useState({
    productcode: '',
    description: '',
    unitprice: '',
    type: '',
    unit: '',
    taxrate: ''
  });

  useEffect(() => {
    console.log('articleDetails:', articleDetails);
    if (articleDetails) {
      setArticlesData({
        productcode: articleDetails.productcode || '',
        description: articleDetails.description || '',
        unitprice: articleDetails.unitprice || '',
        type: articleDetails.type || '',
        unit: articleDetails.unit || '',
        taxrate: articleDetails.tax_rate || ''
      });
    }
  }, [articleDetails]);

  const handleSaveClick = () => {
    if (isNewArticle === true || handleUpdate === undefined) {
      handleSave(articlesData);
    } else {
      handleUpdate(articleDetails.id, articlesData);
    }

    setArticlesData({
      productcode: '',
      description: '',
      unitprice: '',
      type: '',
      unit: '',
      taxrate: ''
    });

    handleClose();
  };

  const handleCloseClick = () => {
    setArticlesData({
      productcode: '',
      description: '',
      unitprice: '',
      type: '',
      unit: '',
      taxrate: ''
    });
    handleClose();
  };

  const taxRateOptions = [
    { value: '0%', label: '0%' },
    { value: '6%', label: '6%' },
    { value: '12%', label: '12%' },
    { value: '25%', label: '25%' }
  ];

  // 'Piece', 'Hour', 'Liter', 'Kilogram', 'Square Meter', 'Cubic Meter', 'Meter', 'Package', 'Set', 'Day', 'Month'
  const unitOptions = [
    { value: 'Piece', label: 'Styck' },
    { value: 'Hour', label: 'Timmar' },
    { value: 'Liter', label: 'Liter' },
    { value: 'Kilogram', label: 'Kilogram' },
    { value: 'Square Meter', label: 'Kvadratmeter' },
    { value: 'Cubic Meter', label: 'Kubikmeter' },
    { value: 'Meter', label: 'Meter' },
    { value: 'Package', label: 'Förpackning' },
    { value: 'Set', label: 'Set' },
    { value: 'Day', label: 'Dag' },
    { value: 'Month', label: 'Månad' }
  ];

  const typeOptions = [
    { value: 'Goods', label: 'Varor' },
    { value: 'Service', label: 'Tjänst' }
  ];

  return (
    <Modal className='fade bd-example-modal-lg' size='lg' show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isNewArticle ? 'Ny artikel' : 'Redigera artikel'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="basic-form">
          <div className='col-xl-12'>
            <div className="col-xl-4">
              <div className='mb-5'>
                Fyll i information om artikeln.
              </div>
            </div>
            <Form onSubmit={(e) => e.preventDefault()}>
              <div className="row">
                <Form.Group className="mb-3 col-xl-6" controlId="productcode">
                  <Form.Label>Produktkod</Form.Label>
                  <Form.Control
                    type="text"
                    value={articlesData.productcode}
                    onChange={(e) => setArticlesData({ ...articlesData, productcode: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-xl-6" controlId="description">
                  <Form.Label>Beskrivning</Form.Label>
                  <Form.Control
                    type="text"
                    value={articlesData.description}
                    onChange={(e) => setArticlesData({ ...articlesData, description: e.target.value })}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb-3 col-xl-6" controlId="unitprice">
                  <Form.Label>Pris (exkl. Moms)</Form.Label>
                  <Form.Control
                    type="text"
                    value={articlesData.unitprice}
                    onChange={(e) => setArticlesData({ ...articlesData, unitprice: e.target.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-xl-6" controlId="tax_rate">
                  <Form.Label>Moms</Form.Label>
                  <Select
                    options={taxRateOptions}
                    value={taxRateOptions.find(option => option.value === articlesData.taxrate)}
                    onChange={(selectedOption) => setArticlesData({ ...articlesData, taxrate: selectedOption.value })}
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className="mb-3 col-xl-6" controlId="enhet">
                  <Form.Label>Enhet</Form.Label>
                  <Select
                    options={unitOptions}
                    value={unitOptions.find(option => option.value === articlesData.unit)}
                    onChange={(selectedOption) => setArticlesData({ ...articlesData, unit: selectedOption.value })}
                  />
                </Form.Group>
                <Form.Group className="mb-3 col-xl-6" controlId="type">
                  <Form.Label>Typ</Form.Label>
                  <Select
                    options={typeOptions}
                    value={typeOptions.find(option => option.value === articlesData.type)}
                    onChange={(selectedOption) => setArticlesData({ ...articlesData, type: selectedOption.value })}
                  />
                </Form.Group>
              </div>
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseClick}>
          Avbryt
        </Button>
        <Button variant="primary" onClick={handleSaveClick}>
          Spara
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewArticleModal;
