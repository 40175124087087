// NonEditableContractDetails.js

import React from 'react';

const NonEditableContractDetails = ({ contractDetails }) => {
  return (
    <div className="basic-form">
      <div className="row mb-3">
        {/* Startdatum and Anställningsform */}
        <div className="col-md-6">
          <strong>Startdatum:</strong> {contractDetails.valid_from}
        </div>
        <div className="col-md-6">
          <strong>Anställningsform:</strong> {contractDetails.employmenttype}
        </div>
      </div>
      <div className="row mb-3">
        {/* Lön and Löneperiod */}
        <div className="col-md-6">
          <strong>Lön:</strong> {contractDetails.salary} SEK
        </div>
        <div className="col-md-6">
          <strong>Löneperiod:</strong> {contractDetails.salary_period}
        </div>
      </div>
      <div className="row mb-3">
        {/* Arbetsvecka and Omfattning */}
        <div className="col-md-6">
          <strong>Arbetsvecka (Timmar):</strong> {contractDetails.work_week_hours} timmar
        </div>
        <div className="col-md-6">
          <strong>Omfattning:</strong> {contractDetails.employmentextent}
        </div>
      </div>
      {/*<div className="row mb-3">
         Skattetabell and Skattekollumn
        <div className="col-md-6">
          <strong>Skattetabell:</strong> {employee.taxtable}
        </div>
        <div className="col-md-6">
          <strong>Skattekollumn:</strong> {employee.taxcolumn}
        </div>
      </div>*/}
      <div className="row mb-3">
        {/* Övriga villkor */}
        <div className="col-md-12">
          <strong>Övriga villkor:</strong> {contractDetails.otherterms}
        </div>
      </div>
    </div>
  );
};

export default NonEditableContractDetails;
