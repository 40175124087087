import React, { useEffect, useState } from 'react';
import { Card, Tabs, Tab, Form, Button, Badge, Table } from 'react-bootstrap';
import Select from "react-select";
import axios from '../../../services/AxiosInstance';
import { useParams, useHistory, Link } from 'react-router-dom';
import swal from "sweetalert";
import { getApiUrl } from '../../../utils/ApiUtils';
import PageTitle from '../../layouts/PageTitle';
import CreateFiscalYearModal from '../Accounting/CreateFiscalYearModal';
import CreateTemplateModal from './CreateTemplateModal';
import FileDropZone from "../SharedComponents/FileDropZone";
import Modal from 'react-bootstrap/Modal';
import RoleModal from './UserRoleModal';
import InviteUserModal from './InviteUserModal';

const CompanySettings = () => {
  const { companyId } = useParams();
  const history = useHistory();

  const [companySettingsKey, setCompanySettingsKey] = useState('general');
  const [bookkeepingSettingsKey, setBookkeepingSettingsKey] = useState('accountingyear');
  const [invoiceSettingsKey, setInvoiceSettingsKey] = useState('invoiceGeneral');

  const [isLoading, setIsLoading] = useState(false);

  const [companyDetails, setCompanyDetails] = useState(initialCompanyDetails());
  const [fiscalYears, setFiscalYears] = useState([]);
  const [accountPlan, setAccountPlan] = useState([]);
  const [paymentAccounts, setPaymentAccounts] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [files, setFiles] = useState([]);

  const [verificationDetails, setVerificationDetails] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  const [associatedUsers, setAssociatedUsers] = useState([]);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allRoles, setAllRoles] = useState([]);
  const [showInviteModal, setShowInviteModal] = useState(false);

  const [pendingInvitations, setPendingInvitations] = useState([]);

  // New invoice settings state
  const [invoiceSettings, setInvoiceSettings] = useState({
    starting_invoice_number: '',
    payment_methods: '',
    approved_for_f_skatt: true,
    default_payment_terms: '',
    default_late_interest_rate: '',
  });

  const optionsCompanyType = [
    { value: "Aktiebolag", label: "Aktiebolag" },
    { value: "Handelsbolag", label: "Handelsbolag" },
    { value: "Enskild firma", label: "Enskild firma" },
  ];

  useEffect(() => {
    fetchCompanyDetails();
    fetchFiscalYears();
    fetchTemplates();
    fetchAccountPlan();
    fetchAssociatedUsers();
    fetchPendingInvitations();
    fetchAllRoles();
    fetchInvoiceSettings();
  }, [companyId]);

  function initialCompanyDetails() {
    return {
      company_name: '',
      company_type: '',
      registration_number: '',
      vat_number: '',
      company_email: '',
      company_phone: '',
      bankgirot: '',
      plusgirot: '',
      contact_email: '',
      contact_phone: '',
      contact_person: '',
      address: '',
      city: '',
      postal_code: '',
      storage_account: '',
      storage_container: ''
    };
  }

  const fetchAllRoles = async () => {
    try {
      const url = getApiUrl(`${companyId}/roles`);
      const { data } = await axios.get(url, { withCredentials: true });
      setAllRoles(data.roles);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };

  const fetchCompanyDetails = async () => {
    try {
      const url = getApiUrl(`${companyId}/companydetails`);
      const { data } = await axios.get(url, { withCredentials: true });
      setCompanyDetails(data);
    } catch (error) {
      console.error('Error getting company details:', error);
    }
  };

  const fetchFiscalYears = async () => {
    try {
      const url = getApiUrl(`${companyId}/accounting/fiscalyears`);
      const { data } = await axios.get(url, { withCredentials: true });
      setFiscalYears(data.fiscal_years);
    } catch (error) {
      console.error('Error getting fiscal years:', error);
    }
  };

  const fetchAccountPlan = async () => {
    try {
      const url = getApiUrl(`${companyId}/accounting/accounts`);
      const { data } = await axios.get(url, { withCredentials: true });
      const payAccounts = data.filter(account => account.is_payment_account === 1);
      setAccountPlan(data);
      setPaymentAccounts(payAccounts);
    } catch (error) {
      console.error('Error getting account plan:', error);
    }
  };

  const fetchTemplates = async () => {
    try {
      const url = getApiUrl(`${companyId}/templates`);
      const { data } = await axios.get(url, { withCredentials: true });
      setTemplates(data.templates);
    } catch (error) {
      console.error('Error getting templates:', error);
    }
  };

  const fetchAssociatedUsers = async () => {
    try {
      const url = getApiUrl(`${companyId}/users`);
      const { data } = await axios.get(url, { withCredentials: true });
      setAssociatedUsers(data.users);
    } catch (error) {
      console.error('Error getting associated users:', error);
    }
  };

  const fetchPendingInvitations = async () => {
    try {
      const url = getApiUrl(`${companyId}/invitations`);
      const { data } = await axios.get(url, { withCredentials: true });
      setPendingInvitations(data.invitations);
    } catch (error) {
      console.error('Error getting invitations:', error);
    }
  };

  const fetchInvoiceSettings = async () => {
    try {
      const url = getApiUrl(`${companyId}/settings/bulk`);
      const { data } = await axios.post(
        url,
        { keys: ['invoice_fskatt_approved', 'invoice_payment_methods', 'invoice_start_number', 'default_payment_terms', 'default_late_interest_rate'] },
        { withCredentials: true }
      );

      // The response will look like:
      // { settings: { invoice_fskatt_approved: "yes", invoice_payment_methods: "bankgiro, plusgiro, swish", invoice_start_number: "1" } }

      setInvoiceSettings({
        starting_invoice_number: data.settings.invoice_start_number || '',
        payment_methods: data.settings.invoice_payment_methods || '',
        approved_for_f_skatt: data.settings.invoice_fskatt_approved === 'yes',
        default_payment_terms: data.settings.default_payment_terms || '',
        default_late_interest_rate: data.settings.default_late_interest_rate || '',
      });
    } catch (error) {
      console.error('Error fetching invoice settings:', error);
    }
  };


  // Handle modal visibility
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleOpenTemplateModal = (templateId = null) => {
    setSelectedTemplateId(templateId);
    setShowTemplateModal(true);
  };

  const handleCloseTemplateModal = () => {
    setShowTemplateModal(false);
    setSelectedTemplateId(null);
    fetchTemplates();
  };

  const handleOpenRoleModal = (user) => {
    setSelectedUser(user);
    setShowRoleModal(true);
  };

  const handleCloseRoleModal = () => {
    setSelectedUser(null);
    setShowRoleModal(false);
  };

  const handleRemoveUser = async (userId) => {
    swal({
      title: "Är du säker?",
      text: "Om du tar bort användaren kommer den att tas bort permanent.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (confirm) => {
      if (confirm) {
        try {
          const url = getApiUrl(`${companyId}/users/${userId}`);
          await axios.delete(url, { withCredentials: true });
          swal("Success", "Användaren har tagits bort", "success");
          fetchAssociatedUsers();
        } catch (error) {
          swal("Error", "Kunde inte ta bort användaren", "error");
        }
      }
    });
  };

  const handleResendInvitation = async (invitationId) => {
    try {
      const resendUrl = getApiUrl(`${companyId}/invitations/${invitationId}/resend`);
      const response = await axios.post(resendUrl, {}, { withCredentials: true });
      if (response.data.message) {
        alert('Invitation resent successfully.');
      }
    } catch (error) {
      console.error('Error resending invitation:', error);
      alert('Failed to resend invitation.');
    }
  };

  const handleDeleteInvitation = async (invitationId) => {
    if (window.confirm('Are you sure you want to delete this invitation?')) {
      try {
        const deleteInvitationUrl = getApiUrl(`${companyId}/invitations/${invitationId}`);
        const response = await axios.delete(deleteInvitationUrl, { withCredentials: true });
        if (response.data.message) {
          setPendingInvitations((prevInvitations) =>
            prevInvitations.filter((inv) => inv.invitation_id !== invitationId)
          );
          alert('Invitation deleted successfully.');
        }
      } catch (error) {
        console.error('Error deleting invitation:', error);
        alert('Failed to delete invitation.');
      }
    }
  };

  const handleSaveFiscalYear = async ({ startDate, endDate, accountingMethod, vatPeriod }) => {
    try {
      const url = getApiUrl(`${companyId}/accounting/fiscalyears`);
      const { data } = await axios.post(url, { startDate, endDate, accountingMethod, vatPeriod }, { withCredentials: true });
      if (data.success) {
        swal("Success", "Fiscal year created successfully", "success");
        setFiscalYears([...fiscalYears, data.fiscal_year]);
      } else {
        swal("Error", "Failed to create fiscal year", "error");
      }
    } catch (error) {
      swal("Error", "Failed to create fiscal year", "error");
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const url = getApiUrl(`${companyId}/updatecompanydetails`);
      const { data } = await axios.put(url, companyDetails, { withCredentials: true });
      if (data.success) {
        swal("Success", "Company details updated successfully", "success");
      } else {
        swal("Error", "Failed to update company details", "error");
      }
    } catch (error) {
      swal("Error", "Failed to update company details", "error");
    }
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      swal("Error", "Please select a file to upload", "error");
      return;
    }
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', files[0]);

    try {
      const url = getApiUrl(`${companyId}/verify-se-file`);
      const { data } = await axios.post(url, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setVerificationDetails(data.data);
      setShowConfirmationModal(true);
    } catch (error) {
      swal("Error", "Failed to verify the file", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveRoles = async (userId, roles) => {
    try {
      const url = getApiUrl(`/companies/${companyId}/users/${userId}/roles`);
      await axios.put(url, { roles }, { withCredentials: true });
      swal("Success", "Roller uppdaterade", "success");
      handleCloseRoleModal();
      fetchAssociatedUsers();
    } catch (error) {
      swal("Error", "Kunde inte uppdatera roller", "error");
    }
  };

  const handleInviteUser = async (email, roles) => {
    try {
      const url = getApiUrl(`/${companyId}/invitations`);
      await axios.post(url, { email, roles }, { withCredentials: true });
      swal("Success", "En inbjudan har skickats.", "success");
      setShowInviteModal(false);
      fetchAssociatedUsers();
    } catch (error) {
      swal("Error", "Kunde inte skicka inbjudan.", "error");
    }
  };

  const handleConfirmImport = async () => {
    setShowConfirmationModal(false);
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', files[0]);

    try {
      const url = getApiUrl(`${companyId}/verifications/import-se-file`);
      const { data } = await axios.post(url, formData, {
        withCredentials: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (data.success) {
        swal("Success", "Filen har importerats", "success");
        setShowConfirmationModal(false);
        setFiles([]);
        setVerificationDetails(null);
        history.push(`accounting/fiscal-years/${data.data}`);
      } else {
        swal("Error", data.message, "error");
      }
    } catch (error) {
      swal("Error", `Kunde inte importera filen`, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToFiscalYearDetails = (fiscalYearId) => () => {
    history.push(`accounting/fiscal-years/${fiscalYearId}`);
  };

  const handleFiscalYearAction = async (fiscalYearId, action) => {
    const actionApiUrl = getApiUrl(`${companyId}/accounting/fiscalyears/${fiscalYearId}/${action}`);
    try {
      const { data } = await axios.put(actionApiUrl, {}, { withCredentials: true });
      if (data.success) {
        swal("Success", data.message, "success");
        fetchFiscalYears();
      }
    } catch (error) {
      swal("Error", `Failed to ${action} fiscal year`, "error");
    }
  };

  const handleSaveInvoiceSettings = async (e) => {
    e.preventDefault();

    try {
      const url = getApiUrl(`${companyId}/settings/bulk/update`);

      // Transform the settings into the expected payload format.
      const updates = [
        { key: 'invoice_start_number', value: invoiceSettings.starting_invoice_number },
        { key: 'invoice_payment_methods', value: invoiceSettings.payment_methods },
        { key: 'invoice_fskatt_approved', value: invoiceSettings.approved_for_f_skatt ? 'yes' : 'no' }
      ];

      const { data } = await axios.post(url, { updates }, { withCredentials: true });

      if (data.success) {
        swal("Success", "Fakturainställningar uppdaterade", "success");
      } else {
        swal("Error", "Kunde inte uppdatera fakturainställningar", "error");
      }
    } catch (error) {
      swal("Error", "Kunde inte uppdatera fakturainställningar", "error");
    }
  };


  const renderCompanyDetailsForm = () => (
    <Form onSubmit={handleSave}>
      <Form.Group className='mb-3' controlId="formCompanyName">
        <Form.Label>Företagsnamn</Form.Label>
        <Form.Control type="text" placeholder="Ange företagsnamn"
          value={companyDetails.company_name || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, company_name: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formCompanyType">
        <Form.Label>Företagstyp</Form.Label>
        <Select
          value={optionsCompanyType.find(option => option.value === companyDetails.company_type)}
          onChange={(selectedOption) => setCompanyDetails({ ...companyDetails, company_type: selectedOption.value })}
          options={optionsCompanyType}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formCompanyOrgNumber">
        <Form.Label>Organisationsnummer</Form.Label>
        <Form.Control type="text"
          value={companyDetails.registration_number || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, registration_number: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formCompanyMoms">
        <Form.Label>Momsnummer</Form.Label>
        <Form.Control type="text"
          value={companyDetails.vat_number || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, vat_number: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formCompanyEmail">
        <Form.Label>E-post</Form.Label>
        <Form.Control type="email" placeholder="Ange epost"
          value={companyDetails.company_email || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, company_email: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formCompanyPhone">
        <Form.Label>Telefon</Form.Label>
        <Form.Control type="text" placeholder="Ange telefonnummer"
          value={companyDetails.company_phone || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, company_phone: e.target.value })}
        />
      </Form.Group>
      <Button className="mt-3" variant="primary" type="submit">
        Spara
      </Button>
    </Form>
  );

  const renderContactDetailsForm = () => (
    <Form onSubmit={handleSave}>
      <Form.Group className='mb-3' controlId="formBillingEmail">
        <Form.Label>E-post</Form.Label>
        <Form.Control type="email" placeholder="Ange epost"
          value={companyDetails.contact_email || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, contact_email: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formBillingPhone">
        <Form.Label>Telefon</Form.Label>
        <Form.Control type="text" placeholder="Ange telefonnummer"
          value={companyDetails.contact_phone || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, contact_phone: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formBillingContactPerson">
        <Form.Label>Kontaktperson</Form.Label>
        <Form.Control type="text" placeholder="Ange kontaktperson"
          value={companyDetails.contact_person || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, contact_person: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formBillingAddress">
        <Form.Label>Fakturaadress</Form.Label>
        <Form.Control type="text" placeholder="Ange address"
          value={companyDetails.address || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, address: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formBillingPostNUmber">
        <Form.Label>Postnummer</Form.Label>
        <Form.Control type="text" placeholder="Ange postnummer"
          value={companyDetails.postal_code || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, postal_code: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formBillingCity">
        <Form.Label>Ort</Form.Label>
        <Form.Control type="text" placeholder="Ange Ort"
          value={companyDetails.city || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, city: e.target.value })}
        />
      </Form.Group>
      <Button className="mt-4" variant="primary" type="submit">
        Spara
      </Button>
    </Form>
  );

  const renderUserSettingsForm = () => (
    <Form onSubmit={handleSave}>
      <Form.Group controlId="formStorageAccount">
        <Form.Label>Storage Account</Form.Label>
        <Form.Control type="text" placeholder="Enter storage account"
          value={companyDetails.storage_account || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, storage_account: e.target.value })}
        />
      </Form.Group>
      <Form.Group controlId="formStorageContainer">
        <Form.Label>Storage Container</Form.Label>
        <Form.Control type="text" placeholder="Enter storage container"
          value={companyDetails.storage_container || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, storage_container: e.target.value })}
        />
      </Form.Group>
      <Button variant="primary" type="submit" className='mt-4'>
        Spara
      </Button>
    </Form>
  );

  const renderBankSettingsForm = () => (
    <Form onSubmit={handleSave}>
      <Form.Group controlId="formBankgirot">
        <Form.Label>Bankgiro</Form.Label>
        <Form.Control type="text" placeholder="Ange bankgiro"
          value={companyDetails.bankgirot || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, bankgirot: e.target.value })}
        />
      </Form.Group>
      <Form.Group controlId="formPlusgirot">
        <Form.Label>Plusgiro</Form.Label>
        <Form.Control type="text" placeholder="Ange plusgiro"
          value={companyDetails.plusgirot || ''}
          onChange={(e) => setCompanyDetails({ ...companyDetails, plusgirot: e.target.value })}
        />
      </Form.Group>
      <Button className='mt-4' variant="primary" type="submit">
        Spara
      </Button>
    </Form>
  );

  const renderFiscalYearsTable = () => (
    <Table responsive hover className='mt-4'>
      <thead>
        <tr>
          <th>Räkenskapsår</th>
          <th>Startdatum</th>
          <th>Slutdatum</th>
          <th>Status</th>
          <th>Redovisningsmetod</th>
          <th>Momsperiod</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {fiscalYears.length > 0 && fiscalYears.map((year) => (
          <tr key={year.fiscal_year_id} onClick={navigateToFiscalYearDetails(year.fiscal_year_id)} style={{ cursor: 'pointer' }}>
            <td>{year.year}</td>
            <td>{new Date(year.start_date).toLocaleDateString()}</td>
            <td>{new Date(year.end_date).toLocaleDateString()}</td>
            <td>
              <Badge variant="primary light">
                {year.state === 'open' ? 'Öppen' : 'Stängd'}
              </Badge>
            </td>
            <td>{year.accounting_method === 'accrual' ? 'Faktureringsmetoden' : 'Kontantmetoden'}</td>
            <td>{year.vat_period === 'monthly' ? 'Månadsvis' :
              year.vat_period === 'quarterly' ? 'Kvartalsvis' :
                'Årsvis'}</td>
            <td>
              <Button onClick={(e) => { e.stopPropagation(); handleFiscalYearAction(year.fiscal_year_id, year.state === 'open' ? 'close' : 'open') }} variant="outline-primary btn-square">
                {year.state === 'open' ? 'Stäng år' : 'Öppna år'}
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const renderAccountsTable = () => (
    <Table responsive hover className='mt-4'>
      <thead>
        <tr>
          <th>Konto</th>
          <th>Kontonamn</th>
        </tr>
      </thead>
      <tbody>
        {paymentAccounts.length > 0 && paymentAccounts.map((account) => (
          <tr key={account.account_id}>
            <td>{account.account_number}</td>
            <td>{account.account_name}</td>
          </tr>
        ))}
      </tbody>
    </Table >
  );

  const renderAccountPlanTable = () => (
    <Table responsive hover className='mt-4'>
      <thead>
        <tr>
          <th>Konto</th>
          <th>Kontonamn</th>
          <th>Typ</th>
          <th>Eget Konto</th>
        </tr>
      </thead>
      <tbody>
        {accountPlan.length > 0 && accountPlan.map((account) => (
          <tr key={account.account_id}>
            <td>{account.account_number}</td>
            <td>{account.account_name}</td>
            <td>{account.account_type}</td>
            <td>{account.is_base_plan ? "" : "Ja"}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const renderTemplatesTable = () => (
    <>
      <Table responsive hover className='mt-4'>
        <thead>
          <tr>
            <th>Template ID</th>
            <th>Template namn</th>
            <th>Beskrivning</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {templates.length > 0 && templates.map((template) => (
            <tr key={template.template_id}>
              <td>{template.template_id}</td>
              <td>{template.template_name}</td>
              <td>{template.description}</td>
              <td>
                <Button onClick={() => handleOpenTemplateModal(template.template_id)} variant="outline-primary btn-square">
                  Redigera
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button variant="primary" onClick={() => handleOpenTemplateModal()}>Create New Template</Button>
    </>
  );

  const renderAssociatedUsersTable = () => (
    <>
      <h4>Användare</h4>
      <Table responsive hover className="mt-4">
        <thead>
          <tr>
            <th>Förnamn</th>
            <th>Efternamn</th>
            <th>E-post</th>
            <th>Telefonnummer</th>
            <th>Roller</th>
            <th>Åtgärder</th>
          </tr>
        </thead>
        <tbody>
          {associatedUsers.length > 0 ? (
            associatedUsers.map((user) => (
              <tr key={user.user_id}>
                <td>{user.first_name}</td>
                <td>{user.last_name}</td>
                <td>{user.email}</td>
                <td>{user.phone_number}</td>
                <td>{user.roles.join(', ')}</td>
                <td>
                  <Button variant="outline-primary" size="sm" onClick={() => handleOpenRoleModal(user)}>
                    Ändra roller
                  </Button>{' '}
                  <Button variant="outline-danger" size="sm" onClick={() => handleRemoveUser(user.user_id)}>
                    Ta bort
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="6">Inga användare kopplade till företaget.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <h4>Inbjudna användare</h4>
      <Table responsive hover className="mt-4">
        <thead>
          <tr>
            <th>E-post</th>
            <th>Roller</th>
            <th>Inbjuden den</th>
            <th>Utgår den</th>
            <th>Åtgärder</th>
          </tr>
        </thead>
        <tbody>
          {pendingInvitations.length > 0 ? (
            pendingInvitations.map((invitation) => (
              <tr key={invitation.invitation_id}>
                <td>{invitation.email}</td>
                <td>{invitation.roles.join(', ')}</td>
                <td>{new Date(invitation.created_at).toLocaleDateString()}</td>
                <td>{new Date(invitation.expires_at).toLocaleDateString()}</td>
                <td>
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => handleResendInvitation(invitation.invitation_id)}
                  >
                    Skicka igen
                  </Button>{' '}
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => handleDeleteInvitation(invitation.invitation_id)}
                  >
                    Ta bort
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">Inga väntande inbjudningar.</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Button className="mt-4" variant="primary" onClick={() => setShowInviteModal(true)}>
        Bjud in användare
      </Button>
    </>
  );

  const renderInvoiceSettingsForm = () => (
    <Form onSubmit={handleSaveInvoiceSettings}>
      <Form.Group className='mb-3' controlId="formStartingInvoiceNumber">
        <Form.Label>Startnummer för fakturor</Form.Label>
        <Form.Control type="number"
          value={invoiceSettings.starting_invoice_number}
          onChange={(e) => setInvoiceSettings({ ...invoiceSettings, starting_invoice_number: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formPaymentMethods">
        <Form.Label>Betalningsmetoder (komma-separerade)</Form.Label>
        <Form.Control type="text"
          value={invoiceSettings.payment_methods}
          onChange={(e) => setInvoiceSettings({ ...invoiceSettings, payment_methods: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formFSkatt">
        <Form.Check
          type="checkbox"
          label="Godkänd för F-skatt"
          checked={invoiceSettings.approved_for_f_skatt}
          onChange={(e) => setInvoiceSettings({ ...invoiceSettings, approved_for_f_skatt: e.target.checked })}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Spara Fakturainställningar
      </Button>
    </Form>
  );

  const renderInvoiceTermsForm = () => (
    <Form onSubmit={handleSaveInvoiceSettings}>
      <Form.Group className='mb-3' controlId="formDefaultPaymentTerms">
        <Form.Label>Standard Betalningsvillkor (dagar)</Form.Label>
        <Form.Control
          type="number"
          value={invoiceSettings.default_payment_terms}
          onChange={(e) => setInvoiceSettings({ ...invoiceSettings, default_payment_terms: e.target.value })}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId="formLateInterestRate">
        <Form.Label>Dröjsmålsränta (%)</Form.Label>
        <Form.Control
          type="number"
          step="0.01"
          value={invoiceSettings.default_late_interest_rate}
          onChange={(e) => setInvoiceSettings({ ...invoiceSettings, default_late_interest_rate: e.target.value })}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Spara Fakturavillkor
      </Button>
    </Form>
  );



  return (
    <>
      <div className="d-flex mb-3">
        <div className="mb-3 align-items-center me-auto">
          <h4 className="fs-24 font-w800">Inställningar</h4>
        </div>
      </div>
      <PageTitle activeMenu="Företaget" motherMenu="Inställningar" />
      <div className="row">
        <div className='col-xl-12'>
          <Card>
            <Card.Header>
              <h4>Företagsinställningar</h4>
            </Card.Header>
            <Card.Body>
              <div className='custom-tab-1'>
                <Tabs
                  id="company-settings-tabs"
                  activeKey={companySettingsKey}
                  onSelect={(k) => setCompanySettingsKey(k)}
                  className="mb-3"
                >
                  <Tab eventKey="general" title="Om Företaget">
                    {renderCompanyDetailsForm()}
                  </Tab>
                  <Tab eventKey="contact" title="Kontaktuppgifter">
                    {renderContactDetailsForm()}
                  </Tab>
                  <Tab eventKey="users" title="Användare">
                    {renderAssociatedUsersTable()}
                  </Tab>
                  <Tab eventKey="bank" title="Bankuppgifter">
                    {renderBankSettingsForm()}
                  </Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className='col-xl-12'>
          <Card>
            <Card.Header>
              <h4>Bokföringsinställningar</h4>
              <Button className="mt-3" variant="primary" onClick={handleShowModal}>
                Skapa nytt räkenskapsår
              </Button>
            </Card.Header>
            <Card.Body>
              <div className='custom-tab-1'>
                <Tabs
                  id="bookkeeping-settings-tabs"
                  activeKey={bookkeepingSettingsKey}
                  onSelect={(k) => setBookkeepingSettingsKey(k)}
                  className="mb-3 mt-4"
                >
                  <Tab eventKey="accountingyear" title="Räkenskapsår">
                    {renderFiscalYearsTable()}
                  </Tab>
                  <Tab eventKey="konton" title="Konton">
                    {renderAccountsTable()}
                  </Tab>
                  <Tab eventKey="kontoplan" title="Kontoplan">
                    {renderAccountPlanTable()}
                  </Tab>
                  <Tab eventKey="import" title="Importera bokföring">
                    <FileDropZone setFiles={setFiles} fileTypes='.se' minHeight='350px' subText='Endast .se filer' acceptedFileTypes='.se' multiple='false' />
                    <Button className="mt-3" variant="primary" onClick={handleUpload} disabled={isLoading}>
                      Kontrollera och ladda upp bokföring
                      {isLoading &&
                        <span className="spinner-border spinner-border-sm ms-3" role="status" aria-hidden="true"></span>
                      }
                    </Button>
                  </Tab>
                  <Tab eventKey="templates" title="Bokföringsmallar">
                    {renderTemplatesTable()}
                  </Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className='col-xl-12'>
          <Card>
            <Card.Header>
              <h4>Faktura och offert-inställningar</h4>
            </Card.Header>
            <Card.Body>
              <div className='custom-tab-1'>
                <Tabs
                  id="invoice-settings-tabs"
                  activeKey={invoiceSettingsKey}
                  onSelect={(k) => setInvoiceSettingsKey(k)}
                  className="mb-3 mt-4"
                >
                  <Tab eventKey="invoiceGeneral" title="Allmänt">
                    {renderInvoiceSettingsForm()}
                  </Tab>
                  {/* Add more tabs for invoice settings as needed */}
                  <Tab eventKey="invoiceTerms" title="Villkor">
                    <p>Under construction...</p>
                    {renderInvoiceTermsForm()}
                  </Tab>
                </Tabs>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      <CreateTemplateModal
        show={showTemplateModal}
        handleClose={handleCloseTemplateModal}
        templateId={selectedTemplateId}
        onTemplateSaved={handleCloseTemplateModal}
        companyId={companyId}
      />

      <CreateFiscalYearModal show={showModal} handleClose={handleCloseModal} handleSave={handleSaveFiscalYear} />

      <Modal show={showConfirmationModal} onHide={() => setShowConfirmationModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Bekräfta Import</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {verificationDetails ? (
            <>
              <p>Företagsnamn: {verificationDetails.companyName}</p>
              <p>Organisationsnummer: {verificationDetails.organizationNumber.orgnr}</p>
              <p>Program: {verificationDetails.exportedFrom.programnamn + ' ' + verificationDetails.exportedFrom.verision}</p>
              {verificationDetails.fiscalYear.length > 0 && (
                <>
                  <p>Räkenskapsår:</p>
                  <ul>
                    {verificationDetails.fiscalYear.map((year, index) => (
                      <li key={index}>År: {year['årsnr']} {year.start} - {year.slut}</li>
                    ))}
                  </ul>
                </>
              )}
            </>
          ) : (
            <p>Laddar detaljer...</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmationModal(false)}>
            Avbryt
          </Button>
          <Button variant="primary" onClick={handleConfirmImport} disabled={isLoading}>
            Importera
            {isLoading &&
              <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
            }
          </Button>
        </Modal.Footer>
      </Modal>

      <RoleModal
        show={showRoleModal}
        handleClose={handleCloseRoleModal}
        user={selectedUser}
        allRoles={allRoles}
        handleSaveRoles={handleSaveRoles}
      />

      <InviteUserModal
        show={showInviteModal}
        handleClose={() => setShowInviteModal(false)}
        allRoles={allRoles}
        handleInviteUser={handleInviteUser}
      />
    </>
  );
};

export default CompanySettings;
