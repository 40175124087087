import React, { useMemo, useState, useEffect } from 'react';
import { useTable, useGlobalFilter, useFilters, usePagination } from 'react-table';
import { useParams, Link, useHistory } from 'react-router-dom';
import axios from '../../../../../services/AxiosInstance';
import { GlobalFilter } from './GlobalFilter';
import NewCustomerModal from './NewCustomerModal';
import './filtering.css';
import PageTitle from '../../../../layouts/PageTitle';
import { NoData } from '../../../SharedComponents/NoData';
import { Dropdown, Spinner } from 'react-bootstrap';
import { getApiUrl } from '../../../../../utils/ApiUtils';
import swal from 'sweetalert';

export const CustomersTable = () => {
  const { companyId } = useParams();
  const history = useHistory();

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(null);
  const [show, setShow] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedCustomer(null);
  };

  const handleDeleteCustomer = (customerId) => {
    const deleteCustomerApiUrl = getApiUrl(`${companyId}/sales/customers/${customerId}`);
    axios
      .delete(deleteCustomerApiUrl, { withCredentials: true })
      .then(() => {
        fetchCustomers();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditCustomer = async (customer) => {
    // Fetch the full customer details
    const getCustomerApiUrl = getApiUrl(`${companyId}/sales/customers/${customer.id}`);
    try {
      const response = await axios.get(getCustomerApiUrl, { withCredentials: true });
      setSelectedCustomer(response.data);
      setShow(true);
    } catch (error) {
      console.error('Error fetching full customer details:', error);
      swal('Fel', 'Kunde inte hämta kunddata för redigering', 'error');
    }
  };

  const handleSave = (newCustomerData) => {
    saveNewCustomer(newCustomerData);
    setShow(false);
  };

  const handleUpdateCustomer = (updatedCustomerData) => {
    const updateCustomerApiUrl = getApiUrl(`${companyId}/sales/customers/${updatedCustomerData.id}`);

    axios
      .put(updateCustomerApiUrl, updatedCustomerData, { withCredentials: true })
      .then(() => {
        fetchCustomers();
      })
      .catch((error) => {
        console.log(error);
      });

    setShow(false);
  };

  const saveNewCustomer = (newData) => {
    const newCustomer = {
      type: newData.type,
      orgnumber: newData.orgnumber,
      vatnumber: newData.vatnumber,
      name: newData.name || '',
      first_name: newData.first_name || '',
      last_name: newData.last_name || '',
      phone: newData.phone || '',
      payment_terms: newData.payment_terms || '',
      addresses: newData.addresses || [],
      contacts: newData.contacts || [],
    };

    const customersApiUrl = getApiUrl(`${companyId}/sales/customers`);
    axios
      .post(customersApiUrl, newCustomer, { withCredentials: true })
      .then(() => {
        fetchCustomers();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRowClick = (customerId) => {
    history.push(`/${companyId}/customers/${customerId}`);
  };

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCustomers = () => {
    setLoading(true);
    const customersApiUrl = getApiUrl(`${companyId}/sales/customers`);
    axios
      .get(customersApiUrl, { withCredentials: true })
      .then((response) => {
        setCustomers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const CUSTOMERS_COLUMNS = [
    {
      Header: 'Kundnummer',
      Footer: 'Kundnummer',
      accessor: 'number',
    },
    {
      Header: 'Namn',
      Footer: 'Namn',
      accessor: 'name',
    },
    /*{
      Header: 'Kontakt',
      Footer: 'Kontakt',
      accessor: 'kontakt',
      Cell: ({ value }) => (value || '-'),
    },*/
    {
      Header: 'Typ',
      Footer: 'Typ',
      accessor: 'type',
      Cell: ({ value }) => {
        const translatedValue = value === 'company' ? 'Företag' : value === 'individual' ? 'Privat' : value;
        return translatedValue;
      },
    },
    {
      Header: '',
      className: 'column-right',
      Cell: ({ row }) => (
        <Dropdown
          className="dropdown ms-auto text-right"
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown.Toggle
            variant=""
            className="btn-link i-false border-0 p-0"
            data-toggle="dropdown"
          >
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                <rect x={0} y={0} width={24} height={24} />
                <circle fill="#000000" cx={5} cy={12} r={2} />
                <circle fill="#000000" cx={12} cy={12} r={2} />
                <circle fill="#000000" cx={19} cy={12} r={2} />
              </g>
            </svg>
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
            <Dropdown.Item onClick={() => handleEditCustomer(row.original)}>
              Redigera kund
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleDeleteCustomer(row.original.id)}>
              Ta bort kund
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ),
      id: 'actions',
    },
  ];

  const columns = useMemo(() => CUSTOMERS_COLUMNS, []);
  const data = useMemo(() => customers, [customers]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { globalFilter } = state;

  return (
    <>
      <PageTitle activeMenu="Kunder" motherMenu="Fakturor och Offerter" />
      <div className="col-12">
        {loading ? (
          <div className="vh-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" className="me-2">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Loading...</div>
          </div>
        ) : customers.length === 0 ? (
          <>
            <NoData message="Det finns inga kunder att visa. Skapa en för att kunna se dem här." />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              <Link
                style={{ marginTop: '20px', alignItems: 'center' }}
                to="#"
                onClick={() => setShow(true)}
                className="btn btn-primary"
              >
                Skapa en ny kund
              </Link>
            </div>
          </>
        ) : (
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Kunder</h4>
              <Link to="#" onClick={() => setShow(true)} className="btn btn-primary">
                Ny Kund
              </Link>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                />
                <table
                  {...getTableProps()}
                  className="table dataTable table-hover display"
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()} key={column.id}>
                            {column.render('Header')}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      return (
                        <tr
                          className="clickable-row"
                          onClick={() => handleRowClick(row.original.id)}
                          {...row.getRowProps()}
                          key={row.id}
                        >
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()} key={cell.column.id}>
                              {cell.value !== null
                                ? cell.render('Cell')
                                : '-'}
                            </td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        <NewCustomerModal
          show={show}
          handleClose={handleClose}
          handleSave={handleSave}
          handleUpdate={handleUpdateCustomer}
          customerDetails={selectedCustomer}
        />
      </div>
    </>
  );
};

export default CustomersTable;
