import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';

const DeliveryOptions = ({ onDeliveryOptionChange, onValidationChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const deliveryOptions = [
    {
      value: 'email',
      label: 'E-post till kund',
      description: 'Vi kommer att maila en länk till kunden där kunden kommer kunna att öppna och se fakturan. Du kommer se när fakturan har öppnats.',
    },
    {
      value: 'download',
      label: 'Ladda ner PDF',
      description: 'Ladda ned fakturan som en pdf. Denna kan sedan skickas till kunden.',
    },
    // Additional options can be added here
  ];

  // Validation schema for email
  const emailSchema = Yup.object().shape({
    email: Yup.string().email('Ogiltigt e-postformat').required('E-postadress krävs'),
  });

  const handleOptionChange = (value) => {
    setSelectedOption(value);
    onDeliveryOptionChange({ method: value, email: '' });
    if (value === 'email') {
      onValidationChange(false); // Reset validation state
    } else {

      onValidationChange(true); // Reset validation state
    }
  };

  return (
    <div style={{ padding: '10px', margin: '10px' }}>
      {deliveryOptions.map((option) => (
        <div key={option.value} style={{ border: '1px solid #e1e2e3', padding: '10px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              id={option.value}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => handleOptionChange(option.value)}
              className="form-check-input"
            />
            <div style={{ marginLeft: '8px', flexGrow: 1 }}>
              <label htmlFor={option.value} style={{ fontWeight: 'bold' }}>
                {option.label}
              </label>
              <p style={{ color: '#888' }}>{option.description}</p>
            </div>
          </div>

          {selectedOption === 'email' && option.value === 'email' && (
            <Formik
              initialValues={{ email: '' }}
              validationSchema={emailSchema}
              onSubmit={() => { }}
              validate={(values) => {
                try {
                  emailSchema.validateSync(values);
                  onValidationChange(true);
                } catch (error) {
                  onValidationChange(false);
                }
              }}
            >
              {({ values, errors, handleChange, handleBlur, touched }) => (
                <div style={{ marginTop: '8px', padding: '8px' }}>
                  <label htmlFor="email">Ange mottagarens epost:</label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                    onChange={(e) => {
                      handleChange(e);
                      onDeliveryOptionChange({ method: selectedOption, email: e.target.value });
                    }}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                  {touched.email && errors.email ? (
                    <div className="invalid-feedback">{errors.email}</div>
                  ) : null}
                </div>
              )}
            </Formik>
          )}
        </div>
      ))}
    </div>
  );
};

export default DeliveryOptions;
