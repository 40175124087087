// NewEmployee.js

import React from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from '../../../../services/AxiosInstance';
import EmployeePersonalDetails from './EmployeeDetailsComponent';
import EmployeeContractDetails from './ContractDetailsComponent';
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle';

const NewEmployee = () => {
  const { companyId } = useParams();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      // Employee Details
      firstname: '',
      lastname: '',
      ssn: '',
      jobtitle: '',
      clearingnumber: '',
      bankaccountnumber: '',
      phonenumber: '',
      email: '',
      address: '',
      postnummer: '',
      ort: '',
      active: true,

      // Contract Details
      startdate: '',
      employmenttype: '',
      salary: '',
      salaryperiod: '',
      workweek: '40',
      employmentextent: '',
      parttimePercentage: '',
      accordingtocollectiveagreement: true,
      taxtable: '',
      taxcolumn: '',
      workplace: '',
      otherterms: '',
    },
    validationSchema: Yup.object({
      // Employee Details Validation
      firstname: Yup.string().required('Förnamn är obligatoriskt'),
      lastname: Yup.string().required('Efternamn är obligatoriskt'),
      ssn: Yup.string().required('Personnummer är obligatoriskt'),
      jobtitle: Yup.string().required('Jobbtitel är obligatoriskt'),
      clearingnumber: Yup.string().required('Clearingnummer är obligatoriskt'),
      bankaccountnumber: Yup.string().required('Bankkonto är obligatoriskt'),
      phonenumber: Yup.string().required('Telefonnummer är obligatoriskt'),
      email: Yup.string().email('Ogiltig e-postadress').required('E-post är obligatoriskt'),
      address: Yup.string().required('Adress är obligatoriskt'),
      postnummer: Yup.string().required('Postnummer är obligatoriskt'),
      ort: Yup.string().required('Ort är obligatoriskt'),

      // Contract Details Validation
      startdate: Yup.date().required('Startdatum är obligatoriskt'),
      employmenttype: Yup.string().required('Anställningsform är obligatoriskt'),
      salary: Yup.number().typeError('Lön måste vara en siffra').required('Lön är obligatoriskt'),
      salaryperiod: Yup.string().required('Löneperiod är obligatoriskt'),
      workweek: Yup.number().typeError('Arbetsvecka måste vara en siffra').required('Arbetsvecka är obligatoriskt'),
      employmentextent: Yup.string().required('Omfattning är obligatoriskt'),
      parttimePercentage: Yup.number().typeError('Procent måste vara en siffra')
        .when('employmentextent', {
          is: 'Part-time',
          then: Yup.number().required('Procent är obligatoriskt')
            .min(1, 'Procent måste vara minst 1')
            .max(99, 'Procent måste vara högst 99'),

          otherwise: Yup.number().nullable(),
        }),
      taxtable: Yup.string().required('Skattetabell är obligatoriskt'),
      taxcolumn: Yup.string().required('Skattekollumn är obligatoriskt'),
      workplace: Yup.string().required('Tjänsteställe är obligatoriskt'),
      otherterms: Yup.string(),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const createEmployeeApiUrl = getApiUrl(`${companyId}/hr/employees`);

        console.log('values:', values);

        const response = await axios.post(
          createEmployeeApiUrl,
          { employeeDetails: values },
          { withCredentials: true }
        );

        if (response.data.success) {
          // Redirect to the employee list page
          history.push(`/${companyId}/employees`);
        } else {
          // Handle error if needed
          alert('Kunde inte spara anställd. Vänligen försök igen.');
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle error if needed
        alert('Ett fel inträffade vid sparande av anställd.');
      }

      // Optionally reset the form after submission
      // resetForm();
    },
  });

  return (
    <>
      <PageTitle activeMenu={"Skapa Ny"} motherMenu={"Personal"} />

      <div>
        <div className="d-flex mb-3">
          <div className="mb-3 align-items-center me-auto">
            <h4 className="fs-24 font-w800">Lägg till anställd</h4>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          {/* Employee Personal Details */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <EmployeePersonalDetails formik={formik} />
                </div>
              </div>
            </div>
          </div>
          {/* Employee Contract Details */}
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <EmployeeContractDetails formik={formik} />
                </div>
              </div>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            Spara
          </button>
        </form>
      </div>
    </>
  );
};

export default NewEmployee;
