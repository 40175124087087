import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Accordion, Card, Spinner, Alert, Badge } from 'react-bootstrap';
import axios from '../../../../services/AxiosInstance';
import NonEditableEmployeePersonalDetails from './NoEditEmployeeDetailsComponent.js';
import NonEditableContractDetails from './NoEditContractDetailsComponent.js';
import EmployeeEditModal from './EmployeeEditModal.js';
import { getApiUrl } from '../../../../utils/ApiUtils';
import PageTitle from '../../../layouts/PageTitle.js';
import moment from 'moment';

const ViewEmployee = () => {
  const { companyId, id } = useParams();

  const [employee, setEmployee] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [salarySpecs, setSalarySpecs] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State to store the ID of the current contract
  const [currentContractId, setCurrentContractId] = useState(null);

  useEffect(() => {
    loadEmployee();
    loadContracts();
  }, []);

  const loadEmployee = async () => {
    const getEmployeeApiUrl = getApiUrl(`${companyId}/hr/employees/${id}`);
    try {
      const result = await axios.get(getEmployeeApiUrl, { withCredentials: true });
      setEmployee(result.data);
      setLoading(false);
    } catch (error) {
      setError('Failed to load employee data.');
      setLoading(false);
    }
  };

  const loadContracts = async () => {
    const getEmployeeContractsApiUrl = getApiUrl(`${companyId}/hr/employees/${id}/contracts`);
    try {
      const result = await axios.get(getEmployeeContractsApiUrl, { withCredentials: true });

      // Parse dates and sort contracts by valid_from date descending (most recent first)
      const sortedContracts = result.data
        .map((contract) => ({
          ...contract,
          valid_from_date: moment(contract.valid_from, 'YYYY-MM-DD').startOf('day'),
          valid_to_date: contract.valid_to
            ? moment(contract.valid_to, 'YYYY-MM-DD').endOf('day')
            : null,
        }))
        .sort((a, b) => b.valid_from_date - a.valid_from_date);

      // Find the current contract
      const today = moment().startOf('day');
      const currentContract = sortedContracts.find((contract) => {
        const { valid_from_date, valid_to_date } = contract;

        // If the contract's start date is in the future, it's not current
        if (valid_from_date.isAfter(today)) {
          return false;
        }

        // If the contract has ended, it's not current
        if (valid_to_date && valid_to_date.isBefore(today)) {
          return false;
        }

        // Contract is current
        return valid_from_date.isSameOrBefore(today) && (!valid_to_date || valid_to_date.isSameOrAfter(today));
      });

      setContracts(sortedContracts);
      setCurrentContractId(currentContract ? currentContract.contract_id : null);
    } catch (error) {
      setError('Failed to load contracts.');
    }
  };

  const handleShowEditModal = () => setShowEditModal(true);
  const handleCloseEditModal = () => setShowEditModal(false);

  const handleSaveChanges = (updatedEmployee) => {
    setEmployee(updatedEmployee);
    setShowEditModal(false);
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: '100vh' }}
      >
        <Alert variant="danger">{error}</Alert>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        motherMenu="Personal"
        activeMenu={`Anställda / ${employee.firstname} ${employee.lastname}`}
      />

      <div className="d-flex justify-content-between align-items-center mb-3">
        <Link to={`/${companyId}/employees`} className="btn btn-link">
          <i className="fa fa-arrow-left" /> Tillbaka
        </Link>
        <h3>{`${employee.firstname} ${employee.lastname}`}</h3>
        <Link to="#" onClick={handleShowEditModal} className="btn btn-primary">
          Ändra
        </Link>
      </div>

      {/* Personal Information Section */}
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Personlig Information</h5>
        </Card.Header>
        <Card.Body>
          <NonEditableEmployeePersonalDetails employee={employee} />
        </Card.Body>
      </Card>

      {/* Contracts Section */}
      <Card className="mb-4">
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center" style={{ width: '100%' }}>
            <h5 className="mb-0">Kontrakt</h5>
            <div className="ml-auto">
              <Link
                to={`/${companyId}/employee/${id}/newcontract`}
                className="btn btn-sm btn-primary"
              >
                Nytt kontrakt
              </Link>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {contracts.length > 0 ? (
            <>
              <Accordion defaultActiveKey="0">
                {contracts.map((contract, i) => {
                  const isCurrent = contract.contract_id === currentContractId;
                  return (
                    <Accordion.Item key={i} eventKey={`${i}`}>
                      <Accordion.Header>
                        {isCurrent && (
                          <Badge bg="success" className="me-2">
                            Aktuellt
                          </Badge>
                        )}
                        {contract.valid_from} -{' '}
                        {contract.valid_to != null ? contract.valid_to : 'Nuvarande'}
                      </Accordion.Header>
                      <Accordion.Collapse eventKey={`${i}`}>
                        <div className="accordion-body">
                          <NonEditableContractDetails contractDetails={contract} />
                        </div>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
              {currentContractId === null && (
                <Alert variant="warning" className="mt-3">
                  Det finns inget aktuellt kontrakt för denna anställda.
                </Alert>
              )}
            </>
          ) : (
            <p>Den anställda har inget kontrakt i systemet.</p>
          )}
        </Card.Body>
      </Card>

      {/* Salary Specifications Section */}
      <Card className="mb-4">
        <Card.Header>
          <h5 className="mb-0">Lönespecifikationer</h5>
        </Card.Header>
        <Card.Body>
          {salarySpecs.length > 0 ? (
            <Accordion defaultActiveKey="0">
              {salarySpecs.map((d, i) => (
                <Accordion.Item key={i} eventKey={`${i}`}>
                  <Accordion.Header>{d.valid_from}</Accordion.Header>
                  <Accordion.Collapse eventKey={`${i}`}>
                    <div>{d.text}</div>
                  </Accordion.Collapse>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <p>Den anställda har inga lönespecifikationer i systemet.</p>
          )}
        </Card.Body>
      </Card>

      {/* Edit Modal */}
      <EmployeeEditModal
        show={showEditModal}
        handleClose={handleCloseEditModal}
        employee={employee}
        companyId={companyId}
        onSave={handleSaveChanges}
      />
    </>
  );
};

export default ViewEmployee;
