// Register.js

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  loadingToggleAction,
  signupAction,
} from '../../store/actions/AuthActions';
//import logo from "../../images/logo-full.png";
import loginbg from "../../images/bg-login.jpg";

function Register(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  const redirectUrl = new URLSearchParams(location.search).get('redirect') || '/';

  // Formik form handling and validation
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required('Du måste ange ett förnamn'),
      lastName: Yup.string()
        .required('Du måste ange ett efternamn'),
      phone: Yup.string()
        .matches(/^[0-9]{10}$/, 'Telefonnumret måste vara 10 siffror')
        .required('Du måste ange ett telefonnummer'),
      email: Yup.string()
        .email('Ogiltig e-postadress')
        .required('Du måste ange en e-postadress'),
      password: Yup.string()
        .min(6, 'Lösenordet måste vara minst 6 tecken')
        .required('Du måste ange ett lösenord'),
    }),
    onSubmit: (values) => {
      dispatch(loadingToggleAction(true));
      dispatch(
        signupAction(
          values.firstName,
          values.lastName,
          values.phone,
          values.email,
          values.password,
          props.history,
          redirectUrl
        )
      );
    },
  });

  return (
    <div className="authincation h-100 p-meddle" style={{ backgroundImage: `url(${loginbg})` }}>
      <div className="container h-100">
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">

                    </div>
                    <h4 className="text-center mb-4">
                      Registrera dig nu för att komma igång med Sveriges enklaste applikation för administration av företag. (Psst! Det är gratis!)
                    </h4>
                    {props.errorMessage && (
                      <div className="alert alert-danger">{props.errorMessage}</div>
                    )}
                    {props.successMessage && (
                      <div className="alert alert-success">{props.successMessage}</div>
                    )}
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Förnamn</strong>
                        </label>
                        <input
                          type="text"
                          name="firstName"
                          className={`form-control ${formik.touched.firstName && formik.errors.firstName ? 'is-invalid' : ''}`}
                          placeholder="Förnamn"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <div className="invalid-feedback">{formik.errors.firstName}</div>
                        ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Efternamn</strong>
                        </label>
                        <input
                          type="text"
                          name="lastName"
                          className={`form-control ${formik.touched.lastName && formik.errors.lastName ? 'is-invalid' : ''}`}
                          placeholder="Efternamn"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="invalid-feedback">{formik.errors.lastName}</div>
                        ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Telefonnummer</strong>
                        </label>
                        <input
                          type="text"
                          name="phone"
                          className={`form-control ${formik.touched.phone && formik.errors.phone ? 'is-invalid' : ''}`}
                          placeholder="0721234567"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <div className="invalid-feedback">{formik.errors.phone}</div>
                        ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>E-post</strong>
                        </label>
                        <input
                          type="email"
                          name="email"
                          className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                          placeholder="email@example.com"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="invalid-feedback">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <div className="form-group mb-3">
                        <label className="mb-1">
                          <strong>Lösenord</strong>
                        </label>
                        <input
                          type="password"
                          name="password"
                          className={`form-control ${formik.touched.password && formik.errors.password ? 'is-invalid' : ''}`}
                          placeholder="Lösenord"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        {formik.touched.password && formik.errors.password ? (
                          <div className="invalid-feedback">{formik.errors.password}</div>
                        ) : null}
                      </div>
                      <div className="text-center mt-4">
                        <button type="submit" className="btn btn-primary btn-block">
                          Skapa konto
                        </button>
                      </div>
                    </form>
                    <div className="new-account mt-3">
                      <p>
                        Har du redan ett konto?{' '}
                        <Link className="text-primary" to="/login">
                          Logga in
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Register);
