// TableRow.js

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';

const TableRow = ({
  index,
  item,
  articles,
  updateRow,
  deleteRow,
  openCreateNewArticleModal,
}) => {
  const [editedItem, setEditedItem] = useState(item);

  useEffect(() => {
    setEditedItem(item);
  }, [item]);

  const calculateTotalAmount = (item) => {
    const quantity = parseFloat(item.quantity) || 0;
    const unitprice = parseFloat(item.unitprice) || 0;
    const total = quantity * unitprice;
    return parseFloat(total.toFixed(2));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log('name', name);
    console.log('value', value);

    const updatedItem = { ...editedItem, [name]: value };
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);

    console.log('updatedItem', updatedItem);

    updateRow(index, updatedItem);
  };

  const taxOptions = [
    { value: '0%', label: '0%' },
    { value: '6%', label: '6%' },
    { value: '12%', label: '12%' },
    { value: '25%', label: '25%' },
  ];

  const unitOptions = [
    { value: 'Piece', label: 'St' },
    { value: 'Day', label: 'Dag' },
    { value: 'Hour', label: 'Tim' },
    { value: 'kg', label: 'kg' },
    { value: 'l', label: 'l' },
    { value: 'm', label: 'm' },
  ];

  const typeOptions = [
    { value: 'Goods', label: 'Varor' },
    { value: 'Service', label: 'Tjänst' },
  ];

  const articleOptions = articles.map((article) => ({
    value: article.id,
    label: article.description,
    articleData: article,
  }));

  const handleArticleChange = (selectedOption) => {
    let updatedItem;
    if (selectedOption) {
      if (selectedOption.articleData) {
        // Existing article selected
        const selectedArticle = selectedOption.articleData;
        updatedItem = {
          ...editedItem,
          article_id: selectedArticle.id,
          description: selectedArticle.description,
          productcode: selectedArticle.productcode,
          unitprice: selectedArticle.unitprice,
          tax_rate: selectedArticle.tax_rate,
          unit: selectedArticle.unit,
          type: selectedArticle.type,
        };
      } else {
        // New article typed but not yet created
        updatedItem = {
          ...editedItem,
          article_id: null,
          description: selectedOption.label,
          // Keep existing values for other fields
        };
      }
    } else {
      // No article selected
      updatedItem = {
        ...editedItem,
        article_id: null,
        description: '',
        // Optionally reset other fields if desired
      };
    }
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);
  };

  const handleArticleCreate = (inputValue) => {
    const updatedItem = {
      ...editedItem,
      article_id: null, // Since it's a new article
      description: inputValue,
      // Keep existing values for other fields
    };
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);

    // Optionally, you might want to open the modal to create a new article
    // openCreateNewArticleModal(updatedItem);
  };

  const handleTaxRateChange = (selectedOption) => {
    const updatedItem = {
      ...editedItem,
      tax_rate: selectedOption ? selectedOption.value : '',
    };
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);
  };

  const handleUnitChange = (selectedOption) => {
    const updatedItem = {
      ...editedItem,
      unit: selectedOption ? selectedOption.value : '',
    };
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);
  };

  const handleTypeChange = (selectedOption) => {
    const updatedItem = {
      ...editedItem,
      type: selectedOption ? selectedOption.value : '',
    };
    updatedItem.total_amount = calculateTotalAmount(updatedItem);
    setEditedItem(updatedItem);
    updateRow(index, updatedItem);
  };

  return (
    <>
      <tr>
        <td></td>
        <td style={{ minWidth: '200px' }}>
          <CreatableSelect
            isClearable
            onChange={handleArticleChange}
            onCreateOption={handleArticleCreate}
            options={articleOptions}
            value={
              editedItem.article_id
                ? {
                  value: editedItem.article_id,
                  label: editedItem.description,
                  articleData: editedItem,
                }
                : editedItem.description
                  ? { value: editedItem.description, label: editedItem.description }
                  : null
            }
            placeholder="Välj artikel eller skapa en ny..."
            menuPortalTarget={document.body}
            menuPosition="fixed"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </td>
        <td>
          <Select
            name="type"
            value={typeOptions.find((option) => option.value === editedItem.type)}
            onChange={handleTypeChange}
            options={typeOptions}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </td>
        <td>
          <input
            type="number"
            name="quantity"
            value={editedItem.quantity}
            onChange={handleChange}
            className="form-control"
          />
        </td>
        <td>
          <Select
            name="unit"
            value={unitOptions.find((option) => option.value === editedItem.unit)}
            onChange={handleUnitChange}
            options={unitOptions}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </td>
        <td>
          <input
            type="number"
            name="unitprice"
            value={editedItem.unitprice}
            onChange={handleChange}
            className="form-control"
          />
        </td>
        <td>
          <Select
            name="tax_rate"
            value={taxOptions.find((option) => option.value === editedItem.tax_rate)}
            onChange={handleTaxRateChange}
            options={taxOptions}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
          />
        </td>
        <td>{calculateTotalAmount(editedItem)}</td>
        <td>
          <div className="d-flex">
            <FontAwesomeIcon
              icon={faTrash}
              className="text-light me-2"
              style={{ cursor: 'pointer' }}
              onClick={() => deleteRow(index)}
            />
            {!editedItem.article_id && editedItem.description && (
              <FontAwesomeIcon
                icon={faPlus}
                className="text-primary ms-3"
                style={{ cursor: 'pointer' }}
                onClick={() => openCreateNewArticleModal(editedItem)}
              />
            )}
          </div>
        </td>
      </tr>
    </>
  );
};

export default TableRow;
