// InvoiceItemsTable.js

import React from 'react';
import { Table, Button } from 'react-bootstrap';
import TableRow from './TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const InvoiceItemsTable = ({
  items,
  articles,
  handleAddItem,
  handleCreateArticle,
  handleDeleteItem,
  updateRow,
  openCreateNewArticleModal,
}) => {
  const handleAddNewRow = () => {
    const newItem = {
      item_id: '',
      description: '',
      quantity: 1,
      unitprice: 0,
      tax_rate: '25%',
      unit: 'Hour',
      type: 'Service',
      total_amount: 0,
    };
    handleAddItem(newItem);
  };

  return (
    <div>
      {items.length > 0 ? (
        <Table responsive className="table dataTable table-hover display">
          <thead>
            <tr>
              <th></th>
              <th>Beskrivning</th>
              <th>Typ</th>
              <th>Antal</th>
              <th>Enhet</th>
              <th>Á-Pris</th>
              <th>Moms</th>
              <th>Summa</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <TableRow
                key={item.item_id || index}
                index={index}
                item={item}
                articles={articles}
                updateRow={updateRow}
                deleteRow={handleDeleteItem}
                handleCreateArticle={handleCreateArticle}
                openCreateNewArticleModal={openCreateNewArticleModal}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="mb-4 ms-4">Lägg till rader på din faktura.</div>
      )}
      <div className="d-flex justify-content-center mt-3 mb-5">
        <Button className="me-2" variant="outline-primary" onClick={handleAddNewRow}>
          <FontAwesomeIcon icon={faPlus} className="me-2" /> Lägg till ny rad
        </Button>
      </div>
    </div>
  );
};

export default InvoiceItemsTable;
