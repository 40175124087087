import React from 'react';
import Select from 'react-select';

const EmployeeContractDetails = ({ formik }) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = formik;

  // Options for the Select components
  const employmentTypes = [
    { value: 'Permanent', label: 'Tillsvidare' },
    { value: 'Probation', label: 'Provanställning' },
    { value: 'Hourly', label: 'Timanställning' },
  ];

  const salaryPeriods = [
    { value: 'Advance', label: 'Förskott' },
    { value: 'Arrears', label: 'Efterskott' },
  ];

  const employmentExtents = [
    { value: 'Fulltime', label: 'Heltid' },
    { value: 'Part-time', label: 'Deltid (specificera i %)' },
  ];

  const taxTables = Array.from({ length: 14 }, (_, i) => ({
    value: (i + 29).toString(),
    label: (i + 29).toString(),
  }));

  const taxColumns = Array.from({ length: 6 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }));

  // Calculate adjusted workweek hours based on percentage
  let adjustedWorkweekHours = null;
  if (
    values.employmentextent === 'Part-time' &&
    values.parttimePercentage &&
    values.workweek
  ) {
    adjustedWorkweekHours =
      (parseFloat(values.workweek) * parseFloat(values.parttimePercentage)) / 100;
  }

  return (
    <>
      <div className="basic-form">
        {/* Startdatum and Anställningsform */}
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Startdatum</label>
            <input
              type="date"
              className={`form-control ${touched.startdate && errors.startdate ? 'is-invalid' : ''
                }`}
              name="startdate"
              value={values.startdate}
              onChange={(e) => setFieldValue('startdate', e.target.value)}
              onBlur={() => setFieldTouched('startdate', true)}
            />
            {touched.startdate && errors.startdate && (
              <div className="invalid-feedback">{errors.startdate}</div>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Anställningsform</label>
            <Select
              name="employmenttype"
              value={employmentTypes.find(
                (option) => option.value === values.employmenttype
              )}
              onChange={(option) => setFieldValue('employmenttype', option.value)}
              onBlur={() => setFieldTouched('employmenttype', true)}
              options={employmentTypes}
              placeholder="Välj..."
            />
            {touched.employmenttype && errors.employmenttype && (
              <div className="text-danger mt-1">{errors.employmenttype}</div>
            )}
          </div>
        </div>

        {/* Lön and Löneperiod */}
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Lön</label>
            <input
              type="number"
              className={`form-control ${touched.salary && errors.salary ? 'is-invalid' : ''
                }`}
              name="salary"
              value={values.salary}
              onChange={(e) => setFieldValue('salary', e.target.value)}
              onBlur={() => setFieldTouched('salary', true)}
            />
            {touched.salary && errors.salary && (
              <div className="invalid-feedback">{errors.salary}</div>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Löneperiod</label>
            <Select
              name="salaryperiod"
              value={salaryPeriods.find(
                (option) => option.value === values.salaryperiod
              )}
              onChange={(option) => setFieldValue('salaryperiod', option.value)}
              onBlur={() => setFieldTouched('salaryperiod', true)}
              options={salaryPeriods}
              placeholder="Välj..."
            />
            {touched.salaryperiod && errors.salaryperiod && (
              <div className="text-danger mt-1">{errors.salaryperiod}</div>
            )}
          </div>
        </div>

        {/* Arbetsvecka and Omfattning */}
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Arbetsvecka (Timmar)</label>
            <input
              type="number"
              className={`form-control ${touched.workweek && errors.workweek ? 'is-invalid' : ''
                }`}
              name="workweek"
              value={values.workweek}
              onChange={(e) => setFieldValue('workweek', e.target.value)}
              onBlur={() => setFieldTouched('workweek', true)}
            />
            {touched.workweek && errors.workweek && (
              <div className="invalid-feedback">{errors.workweek}</div>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Omfattning</label>
            <Select
              name="employmentextent"
              value={employmentExtents.find(
                (option) => option.value === values.employmentextent
              )}
              onChange={(option) => {
                setFieldValue('employmentextent', option.value);
                // Reset parttimePercentage when switching between Fulltime and Part-time
                if (option.value !== 'Part-time') {
                  setFieldValue('parttimePercentage', '');
                }
              }}
              onBlur={() => setFieldTouched('employmentextent', true)}
              options={employmentExtents}
              placeholder="Välj..."
            />
            {touched.employmentextent && errors.employmentextent && (
              <div className="text-danger mt-1">{errors.employmentextent}</div>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            {/* Part-time Percentage Input */}
            {values.employmentextent === 'Part-time' && (
              <>
                <label>Ange deltid i %</label>
                <input
                  type="number"
                  className={`form-control ${touched.parttimePercentage && errors.parttimePercentage
                    ? 'is-invalid'
                    : ''
                    }`}
                  name="parttimePercentage"
                  value={values.parttimePercentage}
                  onChange={(e) =>
                    setFieldValue('parttimePercentage', e.target.value)
                  }
                  onBlur={() => setFieldTouched('parttimePercentage', true)}
                />
                {touched.parttimePercentage && errors.parttimePercentage && (
                  <div className="invalid-feedback">{errors.parttimePercentage}</div>
                )}
              </ >
            )}
          </div>
        </div>



        {/* Display Adjusted Workweek Hours */}
        {adjustedWorkweekHours !== null && (
          <div className="row">
            <div className="col-md-8">
              <p>
                Arbetstid:{' '}
                <strong>
                  {adjustedWorkweekHours.toFixed(2)} timmar per vecka
                </strong>{' '}
                (baserat på deltid {values.parttimePercentage}% av{' '}
                {values.workweek} timmar)
              </p>
            </div>
          </div>
        )}

        {/* Enligt kollektivavtal Checkbox */}
        <div className="form-group mb-4 mt-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="accordingtocollectiveagreement"
              checked={values.accordingtocollectiveagreement}
              onChange={() =>
                setFieldValue(
                  'accordingtocollectiveagreement',
                  !values.accordingtocollectiveagreement
                )
              }
            />
            <label className="form-check-label">Enligt kollektivavtal</label>
          </div>
        </div>

        {/* Skattetabell and Skattekollumn */}
        <div className="row">
          <div className="form-group mb-3 col-md-4">
            <label>Skattetabell</label>
            <Select
              name="taxtable"
              value={taxTables.find((option) => option.value === values.taxtable)}
              onChange={(option) => setFieldValue('taxtable', option.value)}
              onBlur={() => setFieldTouched('taxtable', true)}
              options={taxTables}
              placeholder="Välj..."
            />
            {touched.taxtable && errors.taxtable && (
              <div className="text-danger mt-1">{errors.taxtable}</div>
            )}
          </div>
          <div className="form-group mb-3 col-md-4">
            <label>Skattekollumn</label>
            <Select
              name="taxcolumn"
              value={taxColumns.find(
                (option) => option.value === values.taxcolumn
              )}
              onChange={(option) => setFieldValue('taxcolumn', option.value)}
              onBlur={() => setFieldTouched('taxcolumn', true)}
              options={taxColumns}
              placeholder="Välj..."
            />
            {touched.taxcolumn && errors.taxcolumn && (
              <div className="text-danger mt-1">{errors.taxcolumn}</div>
            )}
          </div>
        </div>

        {/* Tjänsteställe */}
        <div className="row">
          <div className="form-group mb-3 col-md-6">
            <label>Tjänsteställe</label>
            <input
              type="text"
              className={`form-control ${touched.workplace && errors.workplace ? 'is-invalid' : ''
                }`}
              name="workplace"
              value={values.workplace}
              onChange={(e) => setFieldValue('workplace', e.target.value)}
              onBlur={() => setFieldTouched('workplace', true)}
            />
            {touched.workplace && errors.workplace && (
              <div className="invalid-feedback">{errors.workplace}</div>
            )}
          </div>
        </div>

        {/* Övriga villkor */}
        <div className="row">
          <div className="form-group mb-3 col-md-6">
            <label>Övriga villkor</label>
            <input
              type="text"
              className="form-control"
              name="otherterms"
              value={values.otherterms}
              onChange={(e) => setFieldValue('otherterms', e.target.value)}
            />
            {errors.otherterms && (
              <div className="invalid-feedback">{errors.otherterms}</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeContractDetails;
