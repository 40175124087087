import React from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from '../../../../services/AxiosInstance';
import EmployeeContractDetails from './ContractDetailsComponent.js';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getApiUrl } from '../../../../utils/ApiUtils';

const NewContract = () => {
  const { companyId, id } = useParams();

  const formik = useFormik({
    initialValues: {
      startdate: '',
      employmenttype: '',
      salary: '',
      salaryperiod: '',
      workweek: '40',
      employmentextent: '',
      accordingtocollectiveagreement: true,
      taxtable: '',
      taxcolumn: '',
      workplace: '',
      otherterms: '',
      parttimePercentage: '',
    },
    validationSchema: Yup.object({
      startdate: Yup.date().required('Startdatum är obligatoriskt'),
      employmenttype: Yup.string().required('Anställningsform är obligatoriskt'),
      salary: Yup.number()
        .typeError('Lön måste vara en siffra')
        .required('Lön är obligatoriskt'),
      salaryperiod: Yup.string().required('Löneperiod är obligatoriskt'),
      workweek: Yup.number()
        .typeError('Arbetsvecka måste vara en siffra')
        .required('Arbetsvecka är obligatoriskt'),
      employmentextent: Yup.string().required('Omfattning är obligatoriskt'),
      taxtable: Yup.string().required('Skattetabell är obligatoriskt'),
      taxcolumn: Yup.string().required('Skattekollumn är obligatoriskt'),
      workplace: Yup.string().required('Tjänsteställe är obligatoriskt'),
      otherterms: Yup.string(),
      parttimePercentage: Yup.number()
        .when('employmentextent', {
          is: 'Part-time',
          then: Yup.number()
            .required('Ange procent för deltid')
            .min(1, 'Procenten måste vara minst 1%')
            .max(100, 'Procenten kan inte överstiga 100%'),
          otherwise: Yup.number().notRequired(),
        })
        .nullable(),
    }),
    onSubmit: async (values) => {

      console.log("Contract values to save:", values);
      try {
        const createEmployeeContractApiUrl = getApiUrl(
          `${companyId}/hr/employees/${id}/contracts`
        );
        const response = await axios.post(createEmployeeContractApiUrl, values, {
          withCredentials: true,
        });
        console.log(response);
        // Redirect or show success message as needed
      } catch (error) {
        console.error(error);
        // Handle error as needed
      }
    },
  });

  return (
    <div>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3>Nytt kontrakt</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={`/${companyId}/employees`}>Personell</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Ny anställning
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">
                <EmployeeContractDetails formik={formik} />
                <div className="row">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <button type="submit" className="btn btn-primary">
                        Spara
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewContract;
