// InvoiceForm.js

import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import axios from '../../../../services/AxiosInstance';
import FileDropZone from '../../../components/SharedComponents/FileDropZone.js';
import ProductsAndArticlesCard from './components/ProductAndArticlesCard.js';
import DeliveryOptions from './components/InvoiceDeliveryOptions';
import DatePicker from 'react-datepicker';
import { getApiUrl } from '../../../../utils/ApiUtils.js';
import { formatISO, parseISO, differenceInDays } from 'date-fns';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMapMarkerAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../layouts/PageTitle';
import NewArticleModal from '../Factoring/Articles/NewArticleModal.js';
import Select from 'react-select';
import { isEqual } from 'lodash';

function CustomerDetails({ customer }) {
  if (!customer) return null;

  const sectionStyle = {
    border: '1px solid #ddd',
    padding: '15px',
    marginBottom: '15px',
    borderRadius: '5px',
  };

  // Determine address (prefer billing if exists)
  let billingAddress = null;
  if (customer.addresses && customer.addresses.length > 0) {
    billingAddress = customer.addresses.find(a => a.address_type === 'billing') || customer.addresses[0];
  }

  // Determine main contact for companies
  let mainContact = null;
  if (customer.type === 'company' && customer.contacts && customer.contacts.length > 0) {
    mainContact = customer.contacts[0];
  }

  return (
    <div className="row mt-4">
      {/* For a company */}
      {customer.type === 'company' && (
        <>
          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faBuilding} /> Företagsdetaljer</h5>
              <p>
                {customer.name}<br />
                Org. Nr.: {customer.orgnumber}<br />
                VAT. Nr.: {customer.vatnumber || customer.orgnumber}
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faMapMarkerAlt} /> Adress</h5>
              {billingAddress ? (
                <p>
                  {billingAddress.street1} {billingAddress.street2}<br />
                  {billingAddress.postalcode} {billingAddress.city}<br />
                  {billingAddress.country}
                </p>
              ) : (
                <p>Ingen adress</p>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faUser} /> Kontaktperson</h5>
              {mainContact ? (
                <p>
                  {mainContact.contact_name}<br />
                  {mainContact.contact_email}<br />
                  {mainContact.contact_phone}
                </p>
              ) : (
                <p>Ingen kontaktperson</p>
              )}
            </div>
          </div>
        </>
      )}

      {/* For an individual */}
      {customer.type === 'individual' && (
        <>
          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5><FontAwesomeIcon icon={faUser} className='text-green' /> Kunddetaljer</h5>
              <p>
                {customer.first_name} {customer.last_name}<br />
                {customer.phone}
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div style={sectionStyle}>
              <h5>
                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-green" /> Adress
              </h5>
              {billingAddress ? (
                <p>
                  {billingAddress.street1} {billingAddress.street2}<br />
                  {billingAddress.postalcode} {billingAddress.city}<br />
                  {billingAddress.country}
                </p>
              ) : (
                <p>Ingen adress</p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const InvoiceForm = () => {
  const { companyId, invoiceId } = useParams();
  const history = useHistory();

  const leveransOptions = [
    { value: 'Fritt vårt lager', label: 'Fritt vårt lager' },
    { value: 'Fraktfritt', label: 'Fraktfritt' },
    { value: 'Annat', label: 'Annat' },
  ];

  const drojsmalsrantaOptions = [
    { value: '12%', label: '12%' },
    { value: '15%', label: '15%' },
    { value: '18%', label: '18%' },
    { value: 'Annat', label: 'Annat' },
  ];

  const [showNewArticleModal, setShowNewArticleModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleClose = () => {
    setShowNewArticleModal(false);
    setSelectedArticle(null);
  };

  const handleSave = (newArticle) => {
    saveNewArticle(newArticle);
    setShowNewArticleModal(false);
  };

  const saveNewArticle = async (newData) => {
    const newArticle = {
      productcode: newData.productcode,
      description: newData.description,
      unitprice: newData.unitprice,
      type: newData.type,
      taxrate: newData.taxrate,
      unit: newData.unit,
    };

    const createArticleApiUrl = getApiUrl(`${companyId}/articles`);
    try {
      await axios.post(createArticleApiUrl, newArticle, { withCredentials: true });
      fetchArticles();
    } catch (error) {
      console.log(error);
    }
    setSelectedArticle(null);
  };

  const isEditMode = invoiceId ? true : false;

  const [invoiceData, setInvoiceData] = useState({
    customerid: '',
    invoiceid: isEditMode ? invoiceId : null,
    customername: '',
    invoicedate: new Date(),
    deadline: new Date(),
    disable_rounding: false,
    items: [],
    attachments: [],
    leverans: 'Fritt vårt lager',
    drojsmalsranta: '12%',
  });

  const [betalningsvillkor, setBetalningsvillkor] = useState(0);
  const [items, setItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedCustomerValue, setSelectedCustomerValue] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState({ method: '', email: '' });
  const [invoiceAttachments, setInvoiceAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [initialInvoiceData, setInitialInvoiceData] = useState(null);
  const [initialItems, setInitialItems] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [deliveryOptionValid, setDeliveryOptionValid] = useState(false);

  useEffect(() => {
    fetchArticles();
    fetchCompanySettings();
    if (!isEditMode) {
      setInitialInvoiceData(invoiceData);
      setInitialItems(items);
    }
  }, []);

  useEffect(() => {
    if (isEditMode && invoiceId) {
      fetchInvoiceDetails(invoiceId);
    }
  }, [invoiceId, isEditMode]);

  const fetchArticles = async () => {
    const getArticlesApiUrl = getApiUrl(`${companyId}/articles`);
    try {
      const result = await axios.get(getArticlesApiUrl, { withCredentials: true });
      setArticles(result.data.data);
    } catch (error) {
      swal('Error', 'Error fetching articles', 'error');
    }
  };

  const fetchInvoiceDetails = async (invoiceId) => {
    const getInvoiceDetailsApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/details`);
    try {
      const response = await axios.get(getInvoiceDetailsApiUrl, { withCredentials: true });
      const invoiceDataWithParsedDates = {
        ...response.data,
        invoicedate: parseISO(response.data.invoicedate),
        deadline: parseISO(response.data.deadline),
      };

      setInvoiceData(invoiceDataWithParsedDates);
      setItems(response.data.items);
      setInvoiceAttachments(response.data.attachments);
      setInitialInvoiceData(invoiceDataWithParsedDates);
      setInitialItems(response.data.items);

      // Fetch full customer details after knowing the customerid
      if (response.data.customerid) {
        const fullCustomerDetails = await fetchCustomerDetails(response.data.customerid);
        setSelectedCustomerValue(fullCustomerDetails);
        setInvoiceData((prevData) => ({
          ...prevData,
          customerid: fullCustomerDetails.id,
          customername: fullCustomerDetails.type === 'company'
            ? fullCustomerDetails.name
            : `${fullCustomerDetails.first_name} ${fullCustomerDetails.last_name}`
        }));
      }

    } catch (error) {
      swal('Error', 'Error fetching invoice data', 'error');
    }
  };

  const fetchCompanySettings = async () => {
    const getCompanySettingsApiUrl = getApiUrl(`${companyId}/settings`);
    try {
      await axios.get(getCompanySettingsApiUrl, { withCredentials: true });
    } catch (error) {
      swal('Error', 'Error fetching company settings', 'error');
    }
  };

  // Fetch the list of customers for the AsyncSelect
  const fetchCustomers = async (inputValue) => {
    const getCustomersApiUrl = getApiUrl(`${companyId}/sales/customers`);
    try {
      const result = await axios.get(getCustomersApiUrl, { withCredentials: true });
      // The result.data should be an array of customers with at least {id, kundnummer, namn, orgnumber, type}
      // You can filter by inputValue if desired
      const filtered = inputValue
        ? result.data.filter(c =>
          c.name.toLowerCase().includes(inputValue.toLowerCase()) ||
          (c.orgnumber && c.orgnumber.includes(inputValue))
        )
        : result.data;
      return filtered;
    } catch (error) {
      swal('Error', 'Error fetching customers', 'error');
      return [];
    }
  };

  // Fetch full customer details after selection
  const fetchCustomerDetails = async (customerId) => {
    const customerDetailsApi = getApiUrl(`${companyId}/sales/customers/${customerId}`);
    try {
      const res = await axios.get(customerDetailsApi, { withCredentials: true });
      // res.data should include type, addresses, contacts etc.
      return res.data;
    } catch (error) {
      swal('Error', 'Failed to fetch full customer details', 'error');
      return null;
    }
  };

  const handleChangeCustomerSelection = async (value) => {
    if (!value || !value.id) return;
    const fullDetails = await fetchCustomerDetails(value.id);
    console.log('Selected customer:', fullDetails);
    if (fullDetails) {
      setSelectedCustomerValue(fullDetails);

      // Calculate the deadline date
      const invoiceDate = new Date(invoiceData.invoicedate);
      const paymentTerms = fullDetails.payment_terms;
      const deadlineDate = new Date(invoiceDate);
      deadlineDate.setDate(invoiceDate.getDate() + paymentTerms);

      setInvoiceData((prevData) => ({
        ...prevData,
        customerid: fullDetails.id,
        customername: fullDetails.type === 'company'
          ? fullDetails.name
          : `${fullDetails.first_name} ${fullDetails.last_name}`,
        deadline: deadlineDate
      }));
    }
  };

  const handleAddInvoiceItem = (item) => {
    setItems((prevItems) => [...prevItems, item]);
  };

  const handleEditInvoiceItem = (updatedItem) => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.item_id === updatedItem.item_id ? updatedItem : item))
    );
  };

  const handleUpdateInvoiceItem = useCallback((index, updatedItem) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index] = updatedItem;
      return newItems;
    });
  }, []);

  const handleDeleteInvoiceItem = (index) => {
    setItems((prevItems) => prevItems.filter((_, idx) => idx !== index));
  };

  const handleCreateArticle = async (newArticle) => {
    try {
      setArticles((prevArticles) => [...prevArticles, newArticle]);
    } catch (error) {
      console.error('Error saving new article:', error);
    }
  };

  const handleDateChange = (date) => {
    setInvoiceData((prevData) => ({ ...prevData, invoicedate: date }));
    updateBetalningsvillkor(date, invoiceData.deadline);
  };

  const handleDeadlineChange = (date) => {
    setInvoiceData((prevData) => ({ ...prevData, deadline: date }));
    updateBetalningsvillkor(invoiceData.invoicedate, date);
  };

  const updateBetalningsvillkor = (invoicedate, deadline) => {
    if (invoicedate && deadline) {
      const days = differenceInDays(deadline, invoicedate);
      setBetalningsvillkor(days);
    } else {
      setBetalningsvillkor(0);
    }
  };

  useEffect(() => {
    updateBetalningsvillkor(invoiceData.invoicedate, invoiceData.deadline);
  }, [invoiceData.invoicedate, invoiceData.deadline]);

  const handleLeveransChange = (selectedOption) => {
    setInvoiceData((prevData) => ({ ...prevData, leverans: selectedOption.value }));
  };

  const handleDrojsmalsrantaChange = (selectedOption) => {
    setInvoiceData((prevData) => ({ ...prevData, drojsmalsranta: selectedOption.value }));
  };

  const handleRoundingCheckChange = (e) => {
    const isChecked = e.target.checked;
    setInvoiceData((prevData) => ({
      ...prevData,
      disable_rounding: !isChecked,
    }));
  };

  const openCreateNewArticleModal = (item) => {
    setSelectedArticle(item);
    setShowNewArticleModal(true);
  };

  const handlePublish = async () => {
    if (!deliveryOption.method) {
      swal('Warning', 'Please select a delivery option.', 'warning');
      return;
    }

    if (!deliveryOptionValid) {
      swal('Warning', 'Please correct the errors in the delivery options.', 'warning');
      return;
    }

    if (hasUnsavedChanges || !invoiceData.invoiceid) {
      const confirmSave = await swal({
        title: 'Du har osparade ändringar!',
        text: 'Vill du spara utkastet innan du publicerar fakturan?',
        icon: 'warning',
        buttons: {
          cancel: 'Avbryt',
          discard: {
            text: 'Publicera utan att spara',
            value: 'discard',
          },
          save: {
            text: 'Spara och publicera',
            value: 'save',
          },
        },
      });

      if (confirmSave === 'save') {
        try {
          const savedInvoiceId = await handleSaveDraft();
          if (savedInvoiceId) {
            await publishInvoice(savedInvoiceId);
          } else {
            swal('Error', 'Failed to save invoice. Publish cancelled.', 'error');
          }
        } catch (error) {
          swal('Error', 'Failed to save invoice. Publish cancelled.', 'error');
        }
      } else if (confirmSave === 'discard') {
        if (!invoiceData.invoiceid) {
          swal('Error', 'Invoice ID is missing. Please save the invoice before publishing.', 'error');
          return;
        }
        await publishInvoice(invoiceData.invoiceid);
      }
      // If cancelled, do nothing
    } else {
      await publishInvoice(invoiceData.invoiceid);
    }
  };

  const publishInvoice = async (invoiceIdToUse) => {
    if (!invoiceIdToUse) {
      swal('Error', 'Invoice ID is missing. Please save the invoice before publishing.', 'error');
      return;
    }

    const publishInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${invoiceIdToUse}/publish`);

    try {
      const response = await axios.post(
        publishInvoiceApiUrl,
        {
          invoiceId: invoiceIdToUse,
          action: deliveryOption.method,
          email: deliveryOption.method === 'email' ? deliveryOption.email : undefined,
        },
        {
          withCredentials: true,
          responseType: deliveryOption.method === 'download' ? 'blob' : 'json',
        }
      );

      if (deliveryOption.method === 'download') {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'invoice.pdf';
        link.click();
      }

      swal('Success', 'Invoice published successfully', 'success').then(() => {
        history.push(`/${companyId}/invoices`);
      });
    } catch (error) {
      swal('Error', 'Error publishing invoice', 'error');
    }
  };

  const handleFileUpload = async (file) => {
    if (!isEditMode) {
      swal('Warning', 'Please save the invoice before uploading attachments.', 'warning');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('relatedId', invoiceId);
    formData.append('relatedType', 'invoice');

    const uploadAttachmentApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/addattachment`);

    try {
      const response = await axios.post(uploadAttachmentApiUrl, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        swal('Success', `File ${file.name} uploaded successfully`, 'success');
        fetchInvoiceDetails(invoiceId);
      } else {
        swal('Error', `Failed to upload file ${file.name}`, 'error');
      }
    } catch (error) {
      swal('Error', `Failed to upload file ${file.name}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileDelete = async (attachment_id) => {
    const deleteFileAttachmentApiUrl = getApiUrl(
      `${companyId}/invoices/${invoiceId}/removeattachment/${attachment_id}`
    );

    try {
      const response = await axios.post(deleteFileAttachmentApiUrl, {}, { withCredentials: true });

      if (response.data.success) {
        swal('Success', `File deleted successfully`, 'success');
        fetchInvoiceDetails(invoiceId);
      } else {
        swal('Error', `Failed to delete file`, 'error');
      }
    } catch (error) {
      swal('Error', `Error deleting file`, 'error');
    }
  };

  const handleSaveDraft = useCallback(async () => {
    if (!invoiceData.customerid) {
      swal('Error', 'Vänligen välj kund innan du sparar.', 'error');
      return null;
    }

    setIsLoading(true);

    try {
      let res;
      const dataToSend = {
        invoiceid: invoiceData.invoiceid || invoiceId,
        customerid: invoiceData.customerid,
        status: invoiceData.status || 'Draft',
        currency: invoiceData.currency || 'SEK',
        invoicedate: formatISO(invoiceData.invoicedate, { representation: 'date' }),
        deadline: formatISO(invoiceData.deadline, { representation: 'date' }),
        delivery_terms: invoiceData.leverans,
        late_pay_interest: invoiceData.drojsmalsranta,
        invoicefee: invoiceData.invoicefee,
        disable_rounding: invoiceData.disable_rounding ? 1 : 0,
        pdf_url: invoiceData.pdf_url,
        is_published: invoiceData.is_published ? 1 : 0,
        items: items.map((item) => {
          const newItem = { ...item };
          if (!item.item_id || item.isNew) delete newItem.item_id;
          // Remove undefined properties
          Object.keys(newItem).forEach((key) => {
            if (newItem[key] === undefined) delete newItem[key];
          });
          return newItem;
        }),
      };

      if (!invoiceData.invoiceid) {
        // Create new invoice
        const createInvoiceApiUrl = getApiUrl(`${companyId}/invoices`);
        res = await axios.post(createInvoiceApiUrl, dataToSend, { withCredentials: true });
        if (res.data.success) {
          const newInvoiceId = res.data.invoiceId;
          setInvoiceData((prevData) => ({ ...prevData, invoiceid: newInvoiceId }));
          setInitialInvoiceData({ ...invoiceData, invoiceid: newInvoiceId });
          setInitialItems(items);
          setHasUnsavedChanges(false);
          swal('Success', 'Draft saved successfully.', 'success');
          return newInvoiceId;
        }
      } else {
        // Update existing invoice
        const updateInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${invoiceData.invoiceid}`);
        res = await axios.put(updateInvoiceApiUrl, dataToSend, { withCredentials: true });
        if (res.data.success) {
          setInitialInvoiceData(invoiceData);
          setInitialItems(items);
          setHasUnsavedChanges(false);
          swal('Success', 'Draft saved successfully.', 'success');
          return invoiceData.invoiceid;
        }
      }

      swal('Error', 'Failed to save draft.', 'error');
      return null;
    } catch (error) {
      swal('Error', 'Error saving draft.', 'error');
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [invoiceData, items]);

  useEffect(() => {
    if (initialInvoiceData) {
      const invoiceDataChanged = !isEqual(invoiceData, initialInvoiceData);
      const itemsChanged = !isEqual(items, initialItems);
      setHasUnsavedChanges(invoiceDataChanged || itemsChanged);
    }
  }, [invoiceData, items, initialInvoiceData, initialItems]);

  useEffect(() => {
    const unblock = history.block((promptLocation) => {
      if (hasUnsavedChanges) {
        swal({
          title: 'Du har osparade ändringar!',
          text: 'Vill du spara utkastet innan du lämnar sidan?',
          icon: 'warning',
          buttons: {
            cancel: 'Avbryt',
            discard: {
              text: 'Fortsätt utan att spara',
              value: 'discard',
            },
            save: {
              text: 'Spara utkast',
              value: 'save',
            },
          },
        }).then((value) => {
          switch (value) {
            case 'save':
              handleSaveDraft()
                .then(() => {
                  unblock();
                  history.push(promptLocation.pathname);
                })
                .catch(() => {
                  swal('Error', 'Failed to save draft. Navigation cancelled.', 'error');
                });
              break;
            case 'discard':
              setHasUnsavedChanges(false);
              unblock();
              history.push(promptLocation.pathname);
              break;
            default:
              break;
          }
        });
        return false;
      }
      return true;
    });

    return () => {
      if (unblock) {
        unblock();
      }
    };
  }, [hasUnsavedChanges, history, handleSaveDraft]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [hasUnsavedChanges]);

  return (
    <>
      <PageTitle activeMenu="Skapa Faktura" motherMenu="Fakturor" />
      <div className="row">
        {/* Customer Selection */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Kund</h4>
              <div className="row">
                <div className="col-xl-12">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selectedCustomerValue}
                    getOptionLabel={(e) => `${e.number} - ${e.name} - ${e.orgnumber || ''}`}
                    getOptionValue={(e) => e.id}
                    loadOptions={fetchCustomers}
                    onChange={handleChangeCustomerSelection}
                    placeholder="Välj en kund..."
                  />
                </div>
              </div>
              <CustomerDetails customer={selectedCustomerValue} />
            </div>
          </div>
        </div>

        {/* Products and Articles */}
        <div className="col-xl-12">
          <ProductsAndArticlesCard
            invoiceData={invoiceData}
            items={items}
            articles={articles}
            handleAddInvoiceItem={handleAddInvoiceItem}
            handleDeleteInvoiceItem={handleDeleteInvoiceItem}
            handleUpdateInvoiceItem={handleUpdateInvoiceItem}
            handleCreateArticle={handleCreateArticle}
            handleCheckChange={handleRoundingCheckChange}
            openCreateNewArticleModal={openCreateNewArticleModal}
          />
        </div>

        {/* Invoice Terms */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Villkor</h4>
              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <p className="mb-1">Fakturadatum</p>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    selected={invoiceData.invoicedate}
                    onChange={handleDateChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <p className="mb-1">Förfallodatum</p>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    selected={invoiceData.deadline}
                    onChange={handleDeadlineChange}
                  />
                </div>
              </div>

              <div className="row mb-4">
                {/* Betalningsvillkor */}
                <div className="col-md-4 mb-3">
                  <p className="mb-1">Betalningsvillkor (dagar)</p>
                  <input type="number" className="form-control" value={betalningsvillkor} readOnly />
                </div>

                {/* Leverans */}
                <div className="col-md-4 mb-3">
                  <p className="mb-1">Leverans</p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={leveransOptions.find((option) => option.value === invoiceData.leverans)}
                    onChange={handleLeveransChange}
                    options={leveransOptions}
                  />
                </div>

                {/* Dröjsmålsränta */}
                <div className="col-md-4 mb-3">
                  <p className="mb-1">Dröjsmålsränta</p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={drojsmalsrantaOptions.find((option) => option.value === invoiceData.drojsmalsranta)}
                    onChange={handleDrojsmalsrantaChange}
                    options={drojsmalsrantaOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Attachments */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Tilläggsinformation</h4>
              <h4 className="fs-24 font-w800 mt-5">Bifoga fil</h4>
              <div className="row mt-4">
                <div className="col-xl-6">
                  <FileDropZone
                    setFiles={setInvoiceAttachments}
                    mainText="Dra och släpp filer inom det sträckade området för att lägga till bilagor"
                    minHeight="150px"
                    autoUpload={true}
                    handleUpload={handleFileUpload}
                    handleFileDelete={handleFileDelete}
                    predefinedFiles={invoiceAttachments.map((attachment) => ({
                      id: attachment.attachment_id,
                      name: attachment.file_name,
                      size: 10,
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Delivery Options */}
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Leveransmetod</h4>
              <div className="row mb-4">
                <DeliveryOptions
                  onDeliveryOptionChange={setDeliveryOption}
                  onValidationChange={setDeliveryOptionValid}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Publish and Save Draft Buttons */}
        <div className="col-xl-6">
          <div className="text-begin mt-4">
            <button
              onClick={handlePublish}
              className="btn btn-primary btn me-1 me-sm-3"
              disabled={!deliveryOptionValid}
            >
              Publicera
            </button>
            <button onClick={handleSaveDraft} className="btn btn-primary light btn me-sm-3">
              Spara Utkast
            </button>
          </div>
          {hasUnsavedChanges && <p className="text-warning mt-2">Du har osparade ändringar.</p>}
        </div>
      </div>

      <NewArticleModal
        show={showNewArticleModal}
        handleClose={handleClose}
        handleSave={handleSave}
        articleDetails={selectedArticle}
      />
    </>
  );
};

export default InvoiceForm;






/*

import React, { useState, useEffect, useCallback } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import axios from '../../../../services/AxiosInstance';
import FileDropZone from '../../../components/SharedComponents/FileDropZone.js';
import ProductsAndArticlesCard from './components/ProductAndArticlesCard.js';
import DeliveryOptions from './components/InvoiceDeliveryOptions';
import DatePicker from 'react-datepicker';
import { getApiUrl } from '../../../../utils/ApiUtils.js';
import { formatISO, parseISO, differenceInDays } from 'date-fns';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMapMarkerAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import PageTitle from '../../../layouts/PageTitle';
import NewArticleModal from '../Factoring/Articles/NewArticleModal.js';
import Select from 'react-select';
import { isEqual } from 'lodash';

function CustomerDetails({ customer }) {
  if (!customer) {
    return null;
  }

  const sectionStyle = {
    border: '1px solid #ddd',
    padding: '15px',
    marginBottom: '15px',
    borderRadius: '5px',
  };

  return (
    <div className="row mt-4">

      <div className="col-md-4">
        <div style={sectionStyle}>
          <h5>
            <FontAwesomeIcon icon={faUser} /> Kontaktperson
          </h5>
          <p>
            {customer.contactname}
            <br />
            {customer.email}
            <br />
            {customer.phone}
          </p>
        </div>
      </div>

      <div className="col-md-4">
        <div style={sectionStyle}>
          <h5>
            <FontAwesomeIcon icon={faMapMarkerAlt} /> Adress
          </h5>
          <p>
            {customer.street1}
            <br />
            {customer.postalcode} {customer.city}
            <br />
            {customer.country}
          </p>
        </div>
      </div>

      <div className="col-md-4">
        <div style={sectionStyle}>
          <h5>
            <FontAwesomeIcon icon={faBuilding} /> Företagsdetaljer
          </h5>
          <p>
            Org. Nr.: {customer.orgnumber}
            <br />
            VAT. Nr.: {customer.vatnumber || customer.orgnumber}
          </p>
        </div>
      </div>
    </div>
  );
}

const InvoiceForm = () => {
  const { companyId, invoiceId } = useParams();
  const history = useHistory();

  const leveransOptions = [
    { value: 'Fritt vårt lager', label: 'Fritt vårt lager' },
    { value: 'Fraktfritt', label: 'Fraktfritt' },
    { value: 'Annat', label: 'Annat' },
  ];

  const drojsmalsrantaOptions = [
    { value: '12%', label: '12%' },
    { value: '15%', label: '15%' },
    { value: '18%', label: '18%' },
    { value: 'Annat', label: 'Annat' },
  ];

  // Modal state
  const [showNewArticleModal, setShowNewArticleModal] = useState(false);

  // Selected article state
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleClose = () => {
    setShowNewArticleModal(false);
    setSelectedArticle(null);
  };

  const handleSave = (newArticle) => {
    saveNewArticle(newArticle);
    setShowNewArticleModal(false);
  };

  const saveNewArticle = async (newData) => {
    const newArticle = {
      productcode: newData.productcode,
      description: newData.description,
      unitprice: newData.unitprice,
      type: newData.type,
      taxrate: newData.taxrate,
      unit: newData.unit,
    };

    const createArticleApiUrl = getApiUrl(`${companyId}/articles`);

    try {
      await axios.post(createArticleApiUrl, newArticle, { withCredentials: true });
      fetchArticles();
    } catch (error) {
      console.log(error);
    }

    setSelectedArticle(null);
  };

  const isEditMode = invoiceId ? true : false;

  // State variables
  const [invoiceData, setInvoiceData] = useState({
    customerid: '',
    invoiceid: isEditMode ? invoiceId : null,
    customername: '',
    invoicedate: new Date(),
    deadline: new Date(),
    disable_rounding: false,
    items: [],
    attachments: [],
    leverans: 'Fritt vårt lager', // Default value
    drojsmalsranta: '12%', // Default value
  });

  const [betalningsvillkor, setBetalningsvillkor] = useState(0);

  const [items, setItems] = useState([]);
  const [articles, setArticles] = useState([]);
  const [selectedCustomerValue, setSelectedCustomerValue] = useState(null);
  const [deliveryOption, setDeliveryOption] = useState({ method: '', email: '' });
  const [invoiceAttachments, setInvoiceAttachments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // State for detecting unsaved changes
  const [initialInvoiceData, setInitialInvoiceData] = useState(null);
  const [initialItems, setInitialItems] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Delivery option validation
  const [deliveryOptionValid, setDeliveryOptionValid] = useState(false);

  // Fetch articles and company settings on mount
  useEffect(() => {
    fetchArticles();
    fetchCompanySettings();
    if (!isEditMode) {
      setInitialInvoiceData(invoiceData);
      setInitialItems(items);
    }
  }, []);

  // Fetch invoice details if in edit mode
  useEffect(() => {
    if (isEditMode && invoiceId) {
      fetchInvoiceDetails(invoiceId);
    }
  }, [invoiceId, isEditMode]);

  const fetchArticles = async () => {
    const getArticlesApiUrl = getApiUrl(`${companyId}/articles`);
    try {
      const result = await axios.get(getArticlesApiUrl, { withCredentials: true });
      setArticles(result.data.data);
    } catch (error) {
      swal('Error', 'Error fetching articles', 'error');
    }
  };

  const fetchInvoiceDetails = async (invoiceId) => {
    const getInvoiceDetailsApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/details`);
    try {
      const response = await axios.get(getInvoiceDetailsApiUrl, { withCredentials: true });

      // Create a new invoice data object with parsed dates
      const invoiceDataWithParsedDates = {
        ...response.data,
        invoicedate: parseISO(response.data.invoicedate),
        deadline: parseISO(response.data.deadline),
      };

      // Set the invoice data with parsed dates
      setInvoiceData(invoiceDataWithParsedDates);

      setItems(response.data.items);
      setInvoiceAttachments(response.data.attachments);

      // Store initial state after fetching
      setInitialInvoiceData(invoiceDataWithParsedDates);
      setInitialItems(response.data.items);

      // Extract customer data from invoice data
      const customerData = {
        id: response.data.customerid,
        namn: response.data.customername,
        orgnumber: response.data.orgnumber,
        vatnumber: response.data.vatnumber,
        contactname: response.data.contactname,
        email: response.data.email,
        phone: response.data.phone,
        street1: response.data.street1,
        postalcode: response.data.postalcode,
        city: response.data.city,
        country: response.data.country,
        kundnummer: response.data.kundnummer,
      };
      setSelectedCustomerValue(customerData);
    } catch (error) {
      swal('Error', 'Error fetching invoice data', 'error');
    }
  };

  const fetchCompanySettings = async () => {
    const getCompanySettingsApiUrl = getApiUrl(`${companyId}/settings`);
    try {
      await axios.get(getCompanySettingsApiUrl, { withCredentials: true });
    } catch (error) {
      swal('Error', 'Error fetching company settings', 'error');
    }
  };

  // Handle customer selection
  const handleChangeCustomerSelection = (value) => {
    setSelectedCustomerValue(value);
    setInvoiceData((prevData) => ({
      ...prevData,
      customerid: value.id,
      customername: value.namn,
    }));
  };

  // Handle adding invoice item
  const handleAddInvoiceItem = (item) => {
    console.log('Adding item:', item);
    setItems((prevItems) => [...prevItems, item]);
  };

  // Handle editing invoice item
  const handleEditInvoiceItem = (updatedItem) => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.item_id === updatedItem.item_id ? updatedItem : item))
    );
  };

  const handleUpdateInvoiceItem = useCallback((index, updatedItem) => {

    console.log('Updating item:', updatedItem);

    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index] = updatedItem;
      console.log('New items:', newItems);
      return newItems;
    });
  }, []);

  // InvoiceForm.js
  const handleDeleteInvoiceItem = (index) => {
    setItems((prevItems) => prevItems.filter((_, idx) => idx !== index));
  };

  const handleCreateArticle = async (newArticle) => {
    try {
      setArticles((prevArticles) => [...prevArticles, newArticle]);
    } catch (error) {
      console.error('Error saving new article:', error);
    }
  };

  // Handle date changes
  const handleDateChange = (date) => {
    setInvoiceData((prevData) => ({ ...prevData, invoicedate: date }));
    updateBetalningsvillkor(date, invoiceData.deadline);
  };

  const handleDeadlineChange = (date) => {
    setInvoiceData((prevData) => ({ ...prevData, deadline: date }));
    updateBetalningsvillkor(invoiceData.invoicedate, date);
  };

  const updateBetalningsvillkor = (invoicedate, deadline) => {
    if (invoicedate && deadline) {
      const days = differenceInDays(deadline, invoicedate);
      setBetalningsvillkor(days);
    } else {
      setBetalningsvillkor(0);
    }
  };

  useEffect(() => {
    updateBetalningsvillkor(invoiceData.invoicedate, invoiceData.deadline);
  }, [invoiceData.invoicedate, invoiceData.deadline]);

  // Handle Leverans change
  const handleLeveransChange = (selectedOption) => {
    setInvoiceData((prevData) => ({ ...prevData, leverans: selectedOption.value }));
  };

  // Handle Dröjsmålsränta change
  const handleDrojsmalsrantaChange = (selectedOption) => {
    setInvoiceData((prevData) => ({ ...prevData, drojsmalsranta: selectedOption.value }));
  };

  // Handle rounding checkbox change
  const handleRoundingCheckChange = (e) => {
    const isChecked = e.target.checked;
    setInvoiceData((prevData) => ({
      ...prevData,
      disable_rounding: !isChecked,
    }));
  };

  const openCreateNewArticleModal = (item) => {
    setSelectedArticle(item);
    setShowNewArticleModal(true);
  };

  const handlePublish = async () => {
    if (!deliveryOption.method) {
      swal('Warning', 'Please select a delivery option.', 'warning');
      return;
    }

    if (!deliveryOptionValid) {
      swal('Warning', 'Please correct the errors in the delivery options.', 'warning');
      return;
    }

    if (hasUnsavedChanges || !invoiceData.invoiceid) {
      console.log('Unsaved changes or invoice not saved yet');
      // If there are unsaved changes or the invoice hasn't been saved yet
      const confirmSave = await swal({
        title: 'Du har osparade ändringar!',
        text: 'Vill du spara utkastet innan du publicerar fakturan?',
        icon: 'warning',
        buttons: {
          cancel: 'Avbryt',
          discard: {
            text: 'Publicera utan att spara',
            value: 'discard',
          },
          save: {
            text: 'Spara och publicera',
            value: 'save',
          },
        },
      });

      if (confirmSave === 'save') {
        try {
          const savedInvoiceId = await handleSaveDraft(); // Modify handleSaveDraft to return invoiceId

          console.log('Saved invoice ID:', savedInvoiceId);

          if (savedInvoiceId) {
            console.log('Saved draft successfully. Publishing invoice...');
            await publishInvoice(savedInvoiceId);
          } else {
            swal('Error', 'Failed to save invoice. Publish cancelled.', 'error');
          }
        } catch (error) {
          swal('Error', 'Failed to save invoice. Publish cancelled.', 'error');
        }
      } else if (confirmSave === 'discard') {
        if (!invoiceData.invoiceid) {
          // Cannot publish without an invoice ID
          swal('Error', 'Invoice ID is missing. Please save the invoice before publishing.', 'error');
          return;
        }
        await publishInvoice(invoiceData.invoiceid);
      }
      // If user cancels, do nothing
    } else {
      // No unsaved changes and invoice ID exists
      await publishInvoice(invoiceData.invoiceid);
    }
  };

  const publishInvoice = async (invoiceIdToUse) => {
    if (!invoiceIdToUse) {
      swal('Error', 'Invoice ID is missing. Please save the invoice before publishing.', 'error');
      return;
    }

    const publishInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${invoiceIdToUse}/publish`);

    try {
      const response = await axios.post(
        publishInvoiceApiUrl,
        {
          invoiceId: invoiceIdToUse,
          action: deliveryOption.method,
          email: deliveryOption.method === 'email' ? deliveryOption.email : undefined,
        },
        {
          withCredentials: true,
          responseType: deliveryOption.method === 'download' ? 'blob' : 'json',
        }
      );

      if (deliveryOption.method === 'download') {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'invoice.pdf';
        link.click();
      }

      swal('Success', 'Invoice published successfully', 'success').then(() => {
        history.push(`/${companyId}/invoices`);
      });
    } catch (error) {
      swal('Error', 'Error publishing invoice', 'error');
    }
  };


  // Handle file upload
  const handleFileUpload = async (file) => {
    if (!isEditMode) {
      swal('Warning', 'Please save the invoice before uploading attachments.', 'warning');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('relatedId', invoiceId);
    formData.append('relatedType', 'invoice');

    const uploadAttachmentApiUrl = getApiUrl(`${companyId}/invoices/${invoiceId}/addattachment`);

    try {
      const response = await axios.post(uploadAttachmentApiUrl, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        swal('Success', `File ${file.name} uploaded successfully`, 'success');
        fetchInvoiceDetails(invoiceId);
      } else {
        swal('Error', `Failed to upload file ${file.name}`, 'error');
      }
    } catch (error) {
      swal('Error', `Failed to upload file ${file.name}`, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle file delete
  const handleFileDelete = async (attachment_id) => {
    const deleteFileAttachmentApiUrl = getApiUrl(
      `${companyId}/invoices/${invoiceId}/removeattachment/${attachment_id}`
    );

    try {
      const response = await axios.post(deleteFileAttachmentApiUrl, {}, { withCredentials: true });

      if (response.data.success) {
        swal('Success', `File deleted successfully`, 'success');
        fetchInvoiceDetails(invoiceId);
      } else {
        swal('Error', `Failed to delete file`, 'error');
      }
    } catch (error) {
      swal('Error', `Error deleting file`, 'error');
    }
  };

  // Fetch customers
  const fetchCustomers = async () => {
    const getCustomersApiUrl = getApiUrl(`${companyId}/sales/customers`);

    try {
      const result = await axios.get(getCustomersApiUrl, { withCredentials: true });
      return result.data;
    } catch (error) {
      swal('Error', 'Error fetching customers', 'error');
    }
  };

  const handleSaveDraft = useCallback(async () => {

    console.log('handleSaveDraft items:', items);

    if (!invoiceData.customerid) {
      swal('Error', 'Vänligen välj kund innan du sparar.', 'error');
      return null;
    }

    setIsLoading(true);

    try {
      let res;

      // Prepare the data to send to the backend
      const dataToSend = {
        invoiceid: invoiceData.invoiceid || invoiceId,
        customerid: invoiceData.customerid,
        status: invoiceData.status || 'Draft',
        currency: invoiceData.currency || 'SEK',
        invoicedate: formatISO(invoiceData.invoicedate, { representation: 'date' }),
        deadline: formatISO(invoiceData.deadline, { representation: 'date' }),
        leverans: invoiceData.leverans,
        drojsmalsranta: invoiceData.drojsmalsranta,
        invoicefee: invoiceData.invoicefee,
        disable_rounding: invoiceData.disable_rounding ? 1 : 0,
        pdf_url: invoiceData.pdf_url,
        is_published: invoiceData.is_published ? 1 : 0,
        items: items.map((item) => ({
          item_id: item.item_id && !item.isNew ? item.item_id : undefined,
          quantity: item.quantity,
          productcode: item.productcode,
          description: item.description,
          unitprice: item.unitprice,
          tax_rate: item.tax_rate,
          type: item.type,
          unit: item.unit,
          article_id: item.article_id,
        })),
      };

      // Remove undefined properties
      dataToSend.items = dataToSend.items.map((item) => {
        Object.keys(item).forEach((key) => {
          if (item[key] === undefined) {
            console.log('Deleting key:', key);
            delete item[key];
          }
        });
        return item;
      });

      console.log('Data to send:', dataToSend);

      if (!invoiceData.invoiceid) {
        console.log('Creating new invoice...');
        // Create new invoice
        const createInvoiceApiUrl = getApiUrl(`${companyId}/invoices`);
        res = await axios.post(createInvoiceApiUrl, dataToSend, { withCredentials: true });
        if (res.data.success) {
          const newInvoiceId = res.data.invoiceId;
          setInvoiceData((prevData) => ({ ...prevData, invoiceid: newInvoiceId }));
          setInitialInvoiceData({ ...invoiceData, invoiceid: newInvoiceId });
          setInitialItems(items);
          setHasUnsavedChanges(false);
          swal('Success', 'Draft saved successfully.', 'success');
          return newInvoiceId; // Return the new invoice ID
        }
      } else {
        console.log('Updating existing invoice...');
        // Update existing invoice
        const updateInvoiceApiUrl = getApiUrl(`${companyId}/invoices/${invoiceData.invoiceid}`);
        res = await axios.put(updateInvoiceApiUrl, dataToSend, { withCredentials: true });
        if (res.data.success) {
          setInitialInvoiceData(invoiceData);
          setInitialItems(items);
          setHasUnsavedChanges(false);
          swal('Success', 'Draft saved successfully.', 'success');
          return invoiceData.invoiceid; // Return the existing invoice ID
        }
      }

      swal('Error', 'Failed to save draft.', 'error');
      return null;
    } catch (error) {
      swal('Error', 'Error saving draft.', 'error');
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [invoiceData, items]);

  // Detect unsaved changes
  useEffect(() => {
    if (initialInvoiceData) {
      const invoiceDataChanged = !isEqual(invoiceData, initialInvoiceData);
      const itemsChanged = !isEqual(items, initialItems);
      setHasUnsavedChanges(invoiceDataChanged || itemsChanged);
    }
  }, [invoiceData, items, initialInvoiceData, initialItems]);

  useEffect(() => {
    const unblock = history.block((promptLocation) => {
      if (hasUnsavedChanges) {
        console.log('In the block:', items);
        swal({
          title: 'Du har osparade ändringar!',
          text: 'Vill du spara utkastet innan du lämnar sidan?',
          icon: 'warning',
          buttons: {
            cancel: 'Avbryt',
            discard: {
              text: 'Fortsätt utan att spara',
              value: 'discard',
            },
            save: {
              text: 'Spara utkast',
              value: 'save',
            },
          },
        }).then((value) => {
          switch (value) {
            case 'save':
              // Delay handleSaveDraft to allow state updates to complete
              handleSaveDraft()
                .then(() => {
                  unblock(); // Unblock navigation
                  history.push(promptLocation.pathname); // Navigate to the intended location
                })
                .catch(() => {
                  swal('Error', 'Failed to save draft. Navigation cancelled.', 'error');
                });
              break;
            case 'discard':
              setHasUnsavedChanges(false);
              unblock(); // Unblock navigation
              history.push(promptLocation.pathname); // Navigate to the intended location
              break;
            default:
              // Do nothing, stay on the page
              break;
          }
        });
        return false; // Block navigation
      }
      return true; // Allow navigation
    });

    return () => {
      if (unblock) {
        unblock();
      }
    };
  }, [hasUnsavedChanges, history, handleSaveDraft]);


  // Handle browser-level navigation (beforeunload)
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasUnsavedChanges) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return (
    <>
      <PageTitle activeMenu="Skapa Faktura" motherMenu="Fakturor" />
      <div className="row">

        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Kund</h4>
              <div className="row">
                <div className="col-xl-12">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    value={selectedCustomerValue}
                    getOptionLabel={(e) => `${e.kundnummer} - ${e.namn} - ${e.orgnumber}`}
                    getOptionValue={(e) => e.id}
                    loadOptions={fetchCustomers}
                    onChange={handleChangeCustomerSelection}
                    placeholder="Välj en kund..."
                  />
                </div>
              </div>
              <CustomerDetails customer={selectedCustomerValue} />
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <ProductsAndArticlesCard
            invoiceData={invoiceData}
            items={items}
            articles={articles}
            handleAddInvoiceItem={handleAddInvoiceItem}
            handleDeleteInvoiceItem={handleDeleteInvoiceItem}
            handleUpdateInvoiceItem={handleUpdateInvoiceItem}
            handleCreateArticle={handleCreateArticle}
            handleCheckChange={handleRoundingCheckChange}
            openCreateNewArticleModal={(item) => openCreateNewArticleModal(item)}
          />
        </div>

        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Villkor</h4>
              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <div className="example rangeDatePicker">
                    <p className="mb-1">Fakturadatum</p>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      selected={invoiceData.invoicedate}
                      onChange={handleDateChange}
                    />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="example rangeDatePicker">
                    <p className="mb-1">Förfallodatum</p>
                    <DatePicker
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      selected={invoiceData.deadline}
                      onChange={handleDeadlineChange}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-4 mb-3">
                  <p className="mb-1">Betalningsvillkor (dagar)</p>
                  <input
                    type="number"
                    className="form-control"
                    value={betalningsvillkor}
                    readOnly
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <p className="mb-1">Leverans</p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={leveransOptions.find((option) => option.value === invoiceData.leverans)}
                    onChange={handleLeveransChange}
                    options={leveransOptions}
                  />
                </div>

                <div className="col-md-4 mb-3">
                  <p className="mb-1">Dröjsmålsränta</p>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={drojsmalsrantaOptions.find(
                      (option) => option.value === invoiceData.drojsmalsranta
                    )}
                    onChange={handleDrojsmalsrantaChange}
                    options={drojsmalsrantaOptions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Tilläggsinformation</h4>
              <h4 className="fs-24 font-w800 mt-5">Bifoga fil</h4>
              <div className="row mt-4">
                <div className="col-xl-6">
                  <FileDropZone
                    setFiles={setInvoiceAttachments}
                    mainText="Dra och släpp filer inom det sträckade området för att lägga till bilagor till din faktura"
                    minHeight="150px"
                    autoUpload={true}
                    handleUpload={handleFileUpload}
                    handleFileDelete={handleFileDelete}
                    predefinedFiles={invoiceAttachments.map((attachment) => ({
                      id: attachment.attachment_id,
                      name: attachment.file_name,
                      size: 10,
                    }))}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="fs-24 font-w800">Leveransmetod</h4>
              <div className="row mb-4">
                <DeliveryOptions
                  onDeliveryOptionChange={setDeliveryOption}
                  onValidationChange={setDeliveryOptionValid}
                />              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="text-begin mt-4">
            <button
              onClick={handlePublish}
              className="btn btn-primary btn me-1 me-sm-3"
              disabled={!deliveryOptionValid}
            >
              Publicera
            </button>
            <button onClick={handleSaveDraft} className="btn btn-primary light btn me-sm-3">
              Spara Utkast
            </button>
          </div>
          {hasUnsavedChanges && <p className="text-warning mt-2">Du har osparade ändringar.</p>}
        </div>
      </div>

      <NewArticleModal
        show={showNewArticleModal}
        handleClose={handleClose}
        handleSave={handleSave}
        articleDetails={selectedArticle}
      />
    </>
  );
};

export default InvoiceForm;
*/